import { Routes } from '@angular/router';
import { AuthComponent } from './layouts/auth/auth.component';
import { SigninComponent } from './views/auth/user/signin/signin.component';
import { SignupComponent } from './views/auth/user/signup/signup.component';
import { DashboardComponent } from './layouts/dashboard/dashboard.component';
import { SummariesComponent } from './views/dashboard/summaries/summaries.component';
import { OrdersComponent } from './views/dashboard/orders/orders.component';
import { PaymentsComponent } from './views/dashboard/payments/payments.component';
import { DeveloperAreaComponent } from './views/dashboard/developer-area/developer-area.component';
import { ManageComponent } from './views/dashboard/manage/manage.component';
import { TrackComponent } from './views/dashboard/track/track.component';
import { IntroductionComponent } from './views/dashboard/developer-area/introduction/introduction.component';
import { AuthenticationComponent } from './views/dashboard/developer-area/authentication/authentication.component';
import { WalletComponent } from './views/dashboard/developer-area/wallet/wallet.component';
import { OrderComponent } from './views/dashboard/developer-area/order/order.component';
import { DeliveryComponent } from './views/dashboard/developer-area/delivery/delivery.component';
import { StoreComponent } from './views/dashboard/manage/store/store.component';
import { UsersComponent } from './views/dashboard/manage/users/users.component';
import { NewOrderComponent } from './views/dashboard/orders/new-order/new-order.component';
import {
    AuthGuardService as AuthGuard
} from './services/auth-guard.service';
import {
    AdminGuardService as AdminGuard
} from './services/admin-guard.service';
import { NewStoreComponent } from './views/auth/store/new-store/new-store.component';
import { SelectPlanComponent } from './views/auth/store/select-plan/select-plan.component';
import { SelectStoreComponent } from './views/auth/store/select-store/select-store.component';
import { TrackerComponent } from './views/tracker/tracker.component';
import { DeliveryPendings } from './views/dashboard/delivery-pendings/delivery-pendings.component';

export const routes: Routes = [
    {
        path: '', redirectTo: 'track', pathMatch: 'full'
    },
    { path: 'tracker', component: TrackerComponent},
    { path: 'tracker/:code', component: TrackerComponent},
    {
        path: '', component: AuthComponent, children: [
            { path: 'auth/signin', component: SigninComponent },
            { path: 'auth/signin/:role', component: SigninComponent },
            { path: 'auth/signup', component: SignupComponent },
            { path: 'store/new', component: NewStoreComponent },
            { path: 'store/plan', component: SelectPlanComponent },
            { path: 'store/select', component: SelectStoreComponent }
        ]
    },
    {
        path: '', component: DashboardComponent, canActivate: [AuthGuard], children: [
            { path: 'summaries', component: SummariesComponent },
            { path: 'track', component: TrackComponent },
            { path: 'orders', component: OrdersComponent },
            { path: 'new-order', component: NewOrderComponent },
            { path: 'payments', component: PaymentsComponent },
            { path: 'delivery-pendings', component: DeliveryPendings, canActivate: [AdminGuard] },
            {
                path: 'developer-area', component: DeveloperAreaComponent, children: [
                    { path: '', redirectTo: 'introduction', pathMatch: 'full' },
                    { path: 'introduction', component: IntroductionComponent },
                    { path: 'authentication', component: AuthenticationComponent },
                    { path: 'wallet', component: WalletComponent },
                    { path: 'order', component: OrderComponent },
                    { path: 'delivery', component: DeliveryComponent },
                    { path: '**', redirectTo: 'introduction', pathMatch: 'full' }
                ]
            },
            {
                path: 'manage', component: ManageComponent, children: [
                    { path: '', redirectTo: 'store', pathMatch: 'full' },
                    { path: 'store', component: StoreComponent },
                    { path: 'users', component: UsersComponent },
                    { path: '**', redirectTo: 'store', pathMatch: 'full' }
                ]
            },
        ]
    },
    {
        path: '**', redirectTo: 'track', pathMatch: 'full'
    }
];
