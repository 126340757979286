import { Component } from '@angular/core';
import { Menu } from '../../../types/models';
import { DynamicMenuComponent } from '../../../layouts/dynamic-menu/dynamic-menu.component';

@Component({
  selector: 'app-developer-area',
  standalone: true,
  imports: [
    DynamicMenuComponent
  ],
  templateUrl: './developer-area.component.html',
  styleUrl: './developer-area.component.scss'
})
export class DeveloperAreaComponent {
  menus: Menu[] = [
    {
      title: 'Introdução',
      icon: '../../../../../assets/icons/start.svg',
      route: '/developer-area/introduction',
    },
    {
      title: 'Autenticação',
      icon: '../../../../../assets/icons/lock.svg',
      route: '/developer-area/authentication',
    },
    {
      title: 'Carteira',
      icon: '../../../../../assets/icons/wallet.svg',
      route: '/developer-area/wallet',
      children: [
        {
          title: 'Saldo',
          icon: '',
          route: '/developer-area/wallet/balance',
        },
        {
          title: 'Cartões',
          icon: '',
          route: '/developer-area/wallet/cards',
        }
      ]
    },
    {
      title: 'Pedidos',
      icon: '../../../../../assets/icons/order.svg',
      route: '/developer-area/order',
      children: [
        {
          title: 'Criar um pedido',
          icon: '',
          route: '/developer-area/orders/create',
        },
        {
          title: 'Pedidos',
          icon: '',
          route: '/developer-area/orders/list',
        }
      ]
    },
    {
      title: 'Entregadores',
      icon: '../../../../../assets/icons/order.svg',
      route: '/developer-area/delivery',
      children: [
        {
          title: 'Listar entregadores',
          icon: '',
          route: '/developer-area/delivery/list',
        }
      ]
    }
  ];
}


/*
export interface Menu {
    title: string;
    icon: string;
    route: string;
    children?: Menu[];
}
*/