import { Component } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-introduction',
  standalone: true,
  imports: [],
  templateUrl: './introduction.component.html',
  styleUrl: './introduction.component.scss'
})
export class IntroductionComponent {
  constructor(
    private toastr: ToastrService
  ){

  }

  copy(){
     //copy to clipboard
     const url = 'https://api.lojazdelivery.com.br'

     navigator.clipboard.writeText(url)
       .then(() => {
         console.log('URL copied to clipboard');
         this.toastr.show('Link copiado para a área de transferência', '', {positionClass: 'toast-bottom-right'});
       })
  }
}
