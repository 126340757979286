import { Component } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators, AbstractControl, ValidationErrors } from '@angular/forms';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { ApiService } from '../../../../services/api.service';
import { ToastrService } from 'ngx-toastr';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { CommonModule } from '@angular/common';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { AuthService } from '../../../../services/auth.service';

@Component({
  selector: 'app-signup',
  standalone: true,
  imports: [
    RouterLink,
    ReactiveFormsModule,
    MatProgressSpinnerModule,
    CommonModule,
    NgxMaskDirective,
    NgxMaskPipe
  ],
  providers: [
    provideNgxMask()
  ],
  templateUrl: './signup.component.html',
  styleUrl: './signup.component.scss'
})
export class SignupComponent {
  loading: boolean = false;
  showPassword: boolean = false;
  strength: number = 50;

  constructor(
    private api: ApiService,
    private toastr: ToastrService,
    private auth: AuthService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {
    this.activatedRoute.params.subscribe({
      next: () => {
        if(this.auth.isAuthenticated()){
          this.router.navigate(['/store/select']);
        }
      }
  })
  }

  form = new FormGroup({
    name: new FormControl('', [Validators.minLength(4), Validators.required]),
    email: new FormControl('', [Validators.required, Validators.email]),
    password: new FormControl('', [Validators.minLength(8), Validators.required, this.strengthCheck.bind(this)]),
    phoneNumber: new FormControl('', [Validators.required]),
    document: new FormControl('', [Validators.required]),
    type: new FormControl('user')
  })

  strengthCheck(control: AbstractControl): ValidationErrors | null {
    const password = control.value;
    let hasUppercase = /[A-Z]/.test(password);
    let hasLowercase = /[a-z]/.test(password);
    let hasNumber = /\d/.test(password);
    let hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password);
    let has8Chars = password.length >= 8;

    this.strength = (hasUppercase ? 20 : 0) + (hasLowercase ? 20 : 0) + (hasNumber ? 20 : 0) + (hasSpecialChar ? 20 : 0) + (has8Chars ? 20 : 0);

    if (password.length >= 8 && hasUppercase && hasLowercase && hasNumber && hasSpecialChar) {
      return null; // Valid password
    } else {
      return { strength: 'Password does not meet strength requirements' }; // Invalid password
    }
  }

  strengthDescription(){
    let password = this.form.get('password')?.value || '';

    if(password.length < 8){
      return 'Deve conter no mínimo 8 caracteres';
    }
    if(!/[A-Z]/.test(password)){
      return 'Deve conter pelo menos uma letra maiúscula';
    }
    if(!/[a-z]/.test(password)){
      return 'Deve conter pelo menos uma letra minúscula';
    }
    if(!/\d/.test(password)){
      return 'Deve conter pelo menos um número';
    }
    if(!/[!@#$%^&*(),.?":{}|<>]/.test(password)){
      return 'Deve conter pelo menos um caractere especial';
    }
    return 'Senha forte';
  }

  handleRegister() {
    this.loading = true;

    this.api.post('/users', this.form.value).subscribe({
      next: (response: any) => {
        if(response.status){
          this.toastr.show('Usuário cadastrado com sucesso!');
          this.auth.login(this.form.value.email!, this.form.value.password!).then(() => {
            this.loading = false;
          },
          () => {
            this.loading = false;
          });
        }
        else{
          this.toastr.show(response.message);
        }
        this.loading = false;
      },
      error: (error) => {
        console.log(error);
        this.toastr.show('Ocorreu um erro ao cadastrar o usuário. Tente novamente mais tarde.');
        this.loading = false;
      }
    });
  }
}
