import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'brl',
  standalone: true
})
export class BrlPipe implements PipeTransform {

  transform(value: number | string): string {
    if (value == null) {
      return '';
    }

    const numero = typeof value === 'string' ? parseFloat(value) : value;

    if (isNaN(numero)) {
      return '';
    }

    return `R$${numero.toFixed(2).replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, '.')}`;
  }

}
