<div class="container">
    <div *ngIf="showForm" class="showForm">
        <a *ngIf="showLogo" href="https://lojaz.com.br" target="_blank">
            <div class="logo">
                <img src="../../../../assets/icons/logo.svg" width="80">
            </div>
        </a>

        <div class="form">
            <h2>
                Rastrear pedido
            </h2>

            <div class="form-group">
                <label for="code">Código de rastreio</label>
                <input maxlength="19" type="text" class="form-control" id="code" placeholder="Digite o código de rastreio do pedido"
                    [(ngModel)]="trackCode">
            </div>

            <button [disabled]="trackCode == ''" (click)="track()">
                <p *ngIf="!loading" style="color: white;">Rastrear</p>
                <mat-spinner *ngIf="loading" diameter="20"></mat-spinner>
            </button>
        </div>
    </div>

    <div #map class="map" id="map"></div>

    <div class="infos" *ngIf="order?.status">
        <div class="margin">
            <div class="header" *ngIf="(!order.status.includes('CANCELED')) && !order?.status?.includes('CANCELLED')">
                <div class="pfp"
                    *ngIf="order.delivery != null && (order.status != 'CREATED' && order.status != 'PENDING' && (!order.status.includes('CANCELED')) && !order.status.includes('CANCELLED'))">
                    <div *ngIf="order.delivery.picture != null && order.delivery.picture != ''" class="pfp-container"
                        style="background-image: url('{{getDeliveryPicture()}}');"></div>

                    <div *ngIf="order.delivery.picture == null || order.delivery.picture == ''" class="pfp-container">
                        {{order.delivery.name.charAt(0)}}</div>
                </div>
                <div class="txt">
                    <p *ngIf="order.status!='PENDING' && order.status!='CREATED'">{{order.delivery?.name}}</p>

                    <p *ngIf="order.status=='PENDING' || order.status=='CREATED'">Aguarde...</p>
                </div>
            </div>

            <div class="body" [ngSwitch]="order.status">

                <div *ngSwitchCase="'CREATED'" class="status-description">
                    Processando sua solicitação...
                </div>

                <div *ngSwitchCase="'CANCELLED'" class="status-description">
                   Pedido cancelado.
                </div>

                <div *ngSwitchCase="'PENDING'" class="status-description">
                    Buscando motoboy...
                </div>

                <div *ngSwitchCase="'SEARCHING_FOR_DELIVERY'" class="status-description">
                    Buscando motoboy...
                </div>

                <div *ngSwitchCase="'WAITING_DELIVERY_PICKUP'" class="status-description">
                    O entregador está indo buscar seus produtos.
                </div>

                <!-- DELIVERY_PICKEDUP | WAITING_CODE -->

                <div *ngSwitchCase="'DELIVERY_PICKEDUP'" class="status-description">
                    {{order.delivery?.name}} está a caminho do seu endereço.
                </div>

                <div *ngSwitchCase="'WAITING_CODE'" class="status-description">
                    {{order.delivery?.name}} chegou ao seu endereço e está aguardando o código de confirmação.
                </div>

                <div *ngSwitchCase="'COMPLETED'" class="status-description">
                    Seu pedido foi entregue.
                </div>

                <div class="stepper"
                    *ngIf="!(order.status == 'CREATED' || order.status == 'PENDING' || order.status == 'COMPLETED' || order.status == 'CANCELLED' || order.status == 'CANCELLED_BY_DELIVERY' || order.status == 'CANCELLED_BY_STORE')">

                    <div class="step">
                        <mat-progress-bar [mode]="order.status == 'SEARCHING_FOR_DELIVERY'? 'indeterminate' : 'determinate'" value="100"></mat-progress-bar>
                    </div>

                    <div class="step">
                        <mat-progress-bar *ngIf="order.status != 'SEARCHING_FOR_DELIVERY'" [mode]="order.status == 'WAITING_DELIVERY_PICKUP'? 'indeterminate' : 'determinate'" value="100"></mat-progress-bar>
                    </div>

                    <div class="step">
                        <mat-progress-bar *ngIf="order.status != 'SEARCHING_FOR_DELIVERY' && order.status != 'WAITING_DELIVERY_PICKUP'" [mode]="order.status == 'DELIVERY_PICKEDUP'? 'indeterminate' : order.status == 'WAITING_CODE'? 'determinate' : 'indeterminate'" value="100"></mat-progress-bar>
                    </div>
                </div>
            </div>

            <div class="footer">
                <p>
                    Previsão de entrega: 30 ~ 60 minutos
                </p>
                <button (click)="needHelp()">
                    Precisa de ajuda?
                </button>
            </div>

            <div *ngIf="order.status == 'WAITING_CODE' || order.status == 'DELIVERY_PICKEDUP'" class="additional">
                <div class="vehicle-info">
                    <img src="../../../assets/icons/motorbike.svg" alt="" width="21">
                    {{order.vehicle.model || 'Kawasaki Ninja 300'}} -
                    {{order.vehicle.color || 'Preto'}} -
                    {{order.vehicle.plate || 'ABCD1234'}}
                </div>
                <p>
                    Código para receber:
                </p>
                <div class="code-container">
                    {{order.pinCode}}
                </div>                
            </div>
        </div>
    </div>
</div>