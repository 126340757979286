<div class="container">
    <div #map id="map" class="map"></div>
    <div class="side-container">
        <div class="header">
            <div class="header-title">
                <div class="h1">Pedidos</div>
                <div class="line"></div>
            </div>

            <div class="input-container">
                <input type="text" placeholder="Pesquise por código, ID ou CPF" (keyup)="filter($event)">
            </div>

            <div class="input-container">
                <button [routerLink]="['/new-order']">Novo pedido</button>
            </div>
        </div>

        <div class="body">
            <div class="orders-container">
                <div *ngIf="data.orders.length < 1" class="no-orders">
                    Histórico de pedidos vazio.
                </div>
                <app-order-card (click)="setDirections(order)" *ngFor="let order of data.orders" [order]="order">
                </app-order-card>
            </div>
        </div>
    </div>

    <div class="mobile-bottom-container">
        <div class="float-container">
            <app-order-card (click)="setDirections(order)" *ngFor="let order of data.orders" [order]="order">
            </app-order-card>
        </div>
    </div>
</div>