import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from "@angular/core";
import { TimeAgoPipe } from "../../../pipes/time-ago.pipe";
import { OrderStatus } from "../../../types/orderv2";
import { BrlPipe } from "../../../pipes/brl.pipe";
import { StatusPipe } from "../../../pipes/status.pipe";
import { InOutPipe } from "../../../pipes/in-out.pipe";

@Component ({
    selector: 'app-generic-table',
    templateUrl: './generic-table.component.html',
    styleUrls: ['./generic-table.component.scss'],
    standalone: true,
    imports: [
        TimeAgoPipe,
        BrlPipe,
        StatusPipe,
        InOutPipe
    ]
})

export class GenericTableComponent implements OnChanges {
    @Input() data!: any;
    @Output() pageChange: EventEmitter<number> = new EventEmitter<number>();
    @Output() rowClick: EventEmitter<any> = new EventEmitter<any>();
    @Output() actionClick: EventEmitter<any> = new EventEmitter<any>();
    
    handlePagination(page: number) {  
        this.pageChange.emit(page);
    }

    handleClick(row: any){
        this.rowClick.emit(row);
    }

    handleAction(action: string, row: any) {
        this.actionClick.emit({action, row});
    }

    ngOnChanges(): void {
        // console.log(this.data)
    }
}