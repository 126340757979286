<div class="container" [style.background]="'linear-gradient(to right, ' + creditCard.color.primary + ', ' + creditCard.color.secondary + ')'">
    <div class="card-content">
        <div class="card-header">
            <div class="card-title">
                {{creditCard.cardName}}
            </div>
            <div class="card-icon">
                <img [src]="creditCard.networkIcon" alt="" class="src">
            </div>
        </div>
        
        <div class="card-body">
            <div>
                <div class="card-number">
                    XXXX - XXXX - XXXX - {{creditCard.lastFourDigits}}
                </div>
                <div class="card-owner">
                    {{creditCard.ownerName}}
                </div>
            </div>
            <div class="icon">
                <img src="../../../../assets/icons/trash.svg" alt="" class="trash-icon">
            </div>
        </div>
    </div>
</div>