import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'status',
  standalone: true
})
export class StatusPipe implements PipeTransform {

  transform(value: string): string {
    const statusDescription: any = {
      DISABLED: "Desativado",
      ACTIVE: "Ativo",
      BANNED: "Banido",
      PENDING: "Pendente",
      CANCELED: "Cancelado",
      CANCELLED: "Cancelado",
      REFUNDED: "Reembolsado",
      EXPIRED: "Expirado",
      CANCELED_BY_DELIVERY: "Cancelado pela entrega",
      DOCUMENTS_PENDING: "Documentos pendentes",
      DELETED: "Deletado",
      APPROVED: "Aprovado",
      WAITING_CODE: "Aguardando código",
      CANCELED_BY_STORE: "Cancelado pela loja",
      WAITING_DELIVERY_PICKUP: "Aguardando retirada",
      DELIVERY_PICKEDUP: "Retirado",
      SEARCHING_FOR_DELIVERY: "Procurando entrega",
      COMPLETED: "Completo",
      CREATED: "Criado"
    }

    return statusDescription[value.toUpperCase()] || '';
  }
}