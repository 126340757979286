import { Injectable } from '@angular/core';
import { WalletService } from './wallet.service';

@Injectable({
  providedIn: 'root'
})
export class UtilityService {

  constructor(
    private wallet: WalletService
  ) { }

  public hasStore() {
    const data = JSON.parse(localStorage.getItem('data') || '{}');
    data.hasStore = true;
    localStorage.setItem('data', JSON.stringify(data));
  }

  public setStores(stores: any){
    localStorage.setItem('stores', JSON.stringify(stores));
  }

  public setCurrentStore(store: any){
    this.wallet.syncWallet();
    localStorage.setItem('currentStore', JSON.stringify(store));
  }

  public getCurrentStore(){
    return JSON.parse(localStorage.getItem('currentStore') || '{}');
  }

  public setPlanId(planId: string){
    const data = JSON.parse(localStorage.getItem('currentStore') || '{}');
    data.planId = planId;
    localStorage.setItem('currentStore', JSON.stringify(data));
  }
}
