<div class="auth-container">
    <div class="auth-content">
        <div class="auth-logo-container">
            <img src="../../../../assets/icons/logo.svg" alt="">
        </div>

        <div class="auth-form-container">
            <h1>Crie sua conta</h1>

            <form class="auth-form" [formGroup]="form">
                <div class="auth-form-group">
                    <label for="name">
                        <img [src]="form.get('name')?.invalid ? '../../../../assets/icons/circle.svg' : '../../../../assets/icons/check-circle.svg'" alt="">Nome completo</label>
                    <input type="text" id="name" class="form-control" placeholder="Digite seu nome completo" formControlName="name">
                </div> 
                
                <div class="auth-form-group">
                    <label for="email">
                        <img [src]="form.get('email')?.invalid ? '../../../../assets/icons/circle.svg' : '../../../../assets/icons/check-circle.svg'" alt="">Email</label>
                    <input type="email" id="email" class="form-control" placeholder="Digite seu email" formControlName="email">
                </div> 

                <div class="auth-form-group">
                    <label for="phoneNumber">
                        <img [src]="form.get('phoneNumber')?.invalid ? '../../../../assets/icons/circle.svg' : '../../../../assets/icons/check-circle.svg'" alt="">WhatsApp</label>
                    <input mask="(00) 00000-0000" type="text" id="phoneNumber" class="form-control" placeholder="Informe seu número" formControlName="phoneNumber">
                </div> 

                <div class="auth-form-group">
                    <label for="document">
                        <img [src]="form.get('document')?.invalid ? '../../../../assets/icons/circle.svg' : '../../../../assets/icons/check-circle.svg'" alt="">CPF</label>
                    <input mask="000.000.000-00" type="text" id="document" class="form-control" placeholder="Informe seu CPF" formControlName="document">
                </div> 

                <div class="auth-form-group">
                    <label for="password">
                        <img [src]="form.get('password')?.invalid ? '../../../../assets/icons/circle.svg' : '../../../../assets/icons/check-circle.svg'" alt="">Senha
                    </label>
                    <input [type]="showPassword? 'text' : 'password'" id="password" class="form-control" placeholder="Digite sua senha" formControlName="password">
                    <img (click)="showPassword = !showPassword" id="show-password" [src]="showPassword? '../../../../assets/icons/eye-off.svg' : '../../../../assets/icons/eye.svg'" alt="">
                    <div class="strength-container">
                        <div class="strength-bar" [style.width]="strength + '%'"></div>
                    </div>
                    <div [ngSwitch]="strength" class="strength-description">
                        <div class="strength-label">
                            <p>
                                {{strengthDescription()}}
                            </p>
                            <!-- &nbsp; -->
                            <!-- <p *ngSwitchCase="0">
                                 Senha muito fraca
                            </p>
                            <p *ngSwitchCase="20">Senha muito fraca</p>
                            <p *ngSwitchCase="40">Senha fraca</p>
                            <p *ngSwitchCase="60">Senha boa</p>
                            <p *ngSwitchCase="80">Senha forte</p>
                            <p *ngSwitchCase="100">Senha muito forte</p> -->
                        </div>
                    </div>
                </div>

                <div class="auth-form-group auth-submenu">
                    <p [routerLink]="['/auth/signin']">Já tenho uma conta</p>
                    <a href="#" class="auth-link"></a>
                </div> 

                <div class="auth-form-group">
                    <button [disabled]="!form.valid" (click)="handleRegister()" class="auth-btn">
                        <p *ngIf="!loading">Cadastrar</p>
                        <mat-spinner *ngIf="loading" diameter="20"></mat-spinner>
                    </button>
                </div>
            </form>
        </div>
    </div>
</div>