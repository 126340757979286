import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(
    private http: HttpClient
  ) { }

  get(endpoint: string) {
    const url = environment.baseApiUrl + endpoint;
    const token = localStorage.getItem('token');
    return this.http.get(url, { params: { token: `${token}` } });
  }

  getWithoutToken(endpoint: string) {
    const url = environment.baseApiUrl + endpoint;
    return this.http.get(url);
  }

  getWithBearer(endpoint: string) {
    const url = environment.baseApiUrl + endpoint;
    const token = localStorage.getItem('token');
    return this.http.get(url, { headers: { Authorization: `Bearer ${token}` } });
  }

  post(endpoint: string, data: any,) {
    const url = environment.baseApiUrl + endpoint;
    const token = localStorage.getItem('token');

    return this.http.post(url, data, { headers: { Authorization: `Bearer ${token}` } });
  }

  delete(endpoint: string) {
    const url = environment.baseApiUrl + endpoint;
    const token = localStorage.getItem('token');

    return this.http.delete(url, { headers: { Authorization: `Bearer ${token}` } });
  }
}
