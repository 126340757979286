<div class="modal">
    <div class="modal-header">
        <div class="modal-title">
            {{getStatus()}}
        </div>
        <div class="modal-close" (click)="closeDialog()">
            X
        </div>
    </div>

    <div class="modal-body">
        <div class="modal-content">
            @if(!expired){
                <img [src]="pix?.qrCodeImage" alt="">
            }
        </div>
    </div>
    
    <div class="modal-footer">
        @if(!expired){
            Expira em: {{expireSeconds}} segundo(s).
            <div class="input-container" (click)="copyToClipboard(pix?.brCode || '')">
                <img class="icon" [src]="copied? '../../../../assets/icons/checkmark.svg' : '../../../../assets/icons/copy.svg'" alt="">
                <input type="text" [value]="(pix?.brCode)" readonly>
            </div>
        }
        <button [disabled]="expired" (click)="openExternalLink(pix?.paymentLinkUrl || '')">Pagar pelo link</button>
    </div>
</div>