import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GenericTableComponent } from '../../../components/dashboard/generic-table/generic-table.component';
import { ApiService } from '../../../services/api.service';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-delivery-pendings',
  templateUrl: './delivery-pendings.component.html',
  styleUrls: ['./delivery-pendings.component.scss'],
  standalone: true,
  imports: [CommonModule, GenericTableComponent],
})
export class DeliveryPendings {
  delivery_pendings: any = {};
  deliveryLoaded: boolean = false;
  mockDeliverys: any;

  constructor(private api: ApiService, private toastr: ToastrService) {}

  ngOnInit() {
    this.loadDeliverys();
  }

  onRowClick(data: any) {
    // @TODO: futuramente fazer uma validação
    //obtem dados da linha pressionada, lide com eles aqui (abrir modal, etc)
    // console.log(data);
  }

  handleRequest(userId: string, status: string) {
    this.api
      .post('/users/statusController', {
        userId: userId,
        status: status,
      })
      .subscribe({
        next: (response: any) => {
          if (response.status) {
            this.toastr.show(response.message);
            this.delivery_pendings.data = this.delivery_pendings.data.filter(
              (item: any) => item.id !== userId
            );
            this.delivery_pendings.pagination.totalItems--;
          } else {
            this.toastr.show('Não foi possível realizar essa ação');
          }
        },
        error: () => {
          this.toastr.show('Ocorreu um erro, tente novamente!');
        },
      });
  }

  handleAction(event: any) {
    switch (event.action) {
      case 'APPROVE':
        this.handleRequest(event.row.id, 'active');
        break;
      case 'REJECT':
        this.handleRequest(event.row.id, 'canceled');
        break;
      default:
        break;
    }
  }

  loadDeliverys(page: number = 1) {
    this.api.get('/users/deliveries-pendings?limit=10&page=' + page).subscribe({
      next: (response: any) => {
        this.delivery_pendings = response;
        this.delivery_pendings['metadata'] = this.getMetadata();
        this.deliveryLoaded = true;
      },
      error: () => {
        this.toastr.show('Erro ao realizar a operação', 'Erro');
      },
    });
  }

  onPageChange(page: number) {
    this.loadDeliverys(page);
  }

  getMetadata(): any {
    const userToken = localStorage.getItem('token');
    return {
      placeholder: 'Pesquisar',
      title: 'Listagem de entregadores pendentes',
      headers: [
        {
          title: 'Nome',
          key: 'name',
          type: 'text',
        },
        {
          title: 'Status',
          key: 'status',
          type: 'status',
        },
        {
          title: 'Email',
          key: 'email',
          type: 'text',
        },
        {
          title: 'Selfie',
          key: 'selfie',
          type: 'link',
          token: userToken,
          base_url: 'https://api.lojazdelivery.com.br/uploads/documents/',
          link_message: 'Abrir foto',
        },
        {
          title: 'Documento',
          key: 'document',
          type: 'link',
          token: userToken,
          base_url: 'https://api.lojazdelivery.com.br/uploads/documents/',
          link_message: 'Abrir foto',
        },
      ],
      actions: [
        {
          title: 'Aprovar',
          icon: 'checkmark',
          color: 'success',
          action: 'APPROVE',
        },
        {
          title: 'Rejeitar',
          icon: 'x',
          color: 'danger',
          action: 'REJECT',
        },
      ],
    };
  }
}
