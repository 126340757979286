<div class="title">
  Entregadores pendentes
</div>

@if(!deliveryLoaded){
<div class="delivery-pendings-container">
  <div class="empty">
    Carregando...
  </div>
</div>
}
@else {
<div class="delivery-pendings-container">
  <app-generic-table style="width: 100%;" [data]="delivery_pendings" (pageChange)="onPageChange($event)"
    (rowClick)="onRowClick($event)" (actionClick)="handleAction($event)"></app-generic-table>
</div>
}