<div class="auth-container">
    <div class="auth-content">
        <div class="auth-logo-container">
            <img src="../../../../assets/icons/logo.svg" alt="">
        </div>

        <div class="auth-form-container">
            <h1>Cadastre sua loja</h1>

            <form class="auth-form" [formGroup]="form">
                <div class="auth-form-group">
                    <label for="name">
                        <img [src]="form.get('name')?.invalid ? '../../../../assets/icons/circle.svg' : '../../../../assets/icons/check-circle.svg'" alt="">Nome da loja</label>
                    <input type="text" id="name" class="form-control" placeholder="Digite o nome da sua loja" formControlName="name">
                </div>               

                <div class="auth-form-group">
                    <label for="document">
                        <img [src]="form.get('document')?.invalid ? '../../../../assets/icons/circle.svg' : '../../../../assets/icons/check-circle.svg'" alt="">CPF / CNPJ</label>
                    <input mask="CPF_CNPJ" type="text" id="document" class="form-control" placeholder="Informe seu CPF ou CNPJ" formControlName="document">
                </div> 

                <div class="auth-form-group">
                    <label for="whatsapp">
                        <img [src]="form.get('phoneNumber')?.invalid ? '../../../../assets/icons/circle.svg' : '../../../../assets/icons/check-circle.svg'" alt="">WhatsApp da loja</label>
                    <input mask="(00) 00000-0000" type="text" id="whatsapp" class="form-control" placeholder="Informe o WhatsApp da sua loja" formControlName="phoneNumber">
                </div> 

                <div class="auth-form-group">
                    <label for="address">
                        <img [src]="(form.get('address')?.invalid || form.get('latitude')?.invalid || form.get('longitude')?.invalid) ? '../../../../assets/icons/circle.svg' : '../../../../assets/icons/check-circle.svg'" alt="">Endereço</label>
                    <input #search type="text" id="address" class="form-control" placeholder="Exemplo: Rua Z, 1000, Rio de Janeiro - RJ" formControlName="address">
                </div> 

                <!-- <div class="auth-form-group">
                    <label for="website">Website</label>
                    <input type="text" id="website" class="form-control" placeholder="Informe seu número" formControlName="website">
                </div>  -->

                <div class="auth-form-group" style="margin-top: 12px;">
                    <button (click)="handleRegister()" class="auth-btn" [disabled]="!form.valid || loading">
                        <p *ngIf="!loading">Cadastrar</p>
                        <mat-spinner *ngIf="loading" diameter="20"></mat-spinner>
                    </button>
                </div>
            </form>
        </div>
    </div>
</div>