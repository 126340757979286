<div class="auth-container">
    <div class="auth-content">
        <div class="auth-logo-container">
            <img src="../../../../assets/icons/logo.svg" alt="">
        </div>

        <div class="auth-form-container">
            <h1>Entre para continuar</h1>

            <form class="auth-form" [formGroup]="form">
                <div class="auth-form-group">
                    <label for="email">Email</label>
                    <input type="email" id="email" class="form-control" placeholder="Digite seu email" formControlName="email">
                </div> 

                <div class="auth-form-group">
                    <label for="password">Senha</label>
                    <input [type]="showPassword? 'text' : 'password'" id="password" class="form-control" placeholder="Digite sua senha" formControlName="password">
                    <img (click)="showPassword = !showPassword" id="show-password" [src]="showPassword? '../../../../assets/icons/eye-off.svg' : '../../../../assets/icons/eye.svg'" alt="">
                </div>

                <div class="auth-form-group auth-submenu">
                    <p [routerLink]="['/auth/signup']">Crie sua conta</p>
                    <a href="#" class="auth-link">Esqueceu sua senha?</a>
                </div> 

                <div class="auth-form-group">
                    <button (click)="handleLogin()" class="auth-btn" [disabled]="!form.valid || loading">Entrar</button>
                </div>
            </form>
        </div>
    </div>
</div>