import { Component } from '@angular/core';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { AccessUser } from '../../../../types/models';

@Component({
  selector: 'app-users',
  standalone: true,
  imports: [
    MatTableModule,
  ],
  templateUrl: './users.component.html',
  styleUrl: './users.component.scss'
})
export class UsersComponent {
  users: AccessUser[] = [
    {
      id: 1,
      name: 'lucas',
      email: 'lucas@lojaz.com.br',
      role: 'owner',
      addedBy: '',
      createdAt: '2021-01-01',
      status: 'active'
    },
    {
      id: 2,
      name: 'weslley',
      email: 'weslley@lojaz.com.br',
      role: 'dev',
      addedBy: 'lucas',
      createdAt: '2021-01-01',
      status: 'active'
    },
    {
      id: 3,
      name: 'luiz',
      email: 'luiz@lojaz.com.br',
      role: 'sup',
      addedBy: 'lucas',
      createdAt: '2021-01-01',
      status: 'active'
    }
  ];
}