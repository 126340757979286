import { AfterViewInit, CUSTOM_ELEMENTS_SCHEMA, Component, ElementRef, NgZone, OnInit, ViewChild } from '@angular/core';
import { OrderTrack } from '../../../types/order';
import { OrderCardComponent } from '../../../components/dashboard/track/order-card/order-card.component';
import { CommonModule } from '@angular/common';
import { Socket, SocketService } from '../../../services/socket.service';
import { RouterLink } from '@angular/router';
import { ApiService } from '../../../services/api.service';
import { UtilityService } from '../../../services/utility';

declare const google: any;

interface Direction {
  origin: Location,
  destination: Location,
  travelMode: any
}

interface Location {
  lat: number,
  lng: number,
}

export interface OnDeliveryLocationChangeEvent extends Location {
  uuid: string;
}

export enum EventTypeEnum {
  STATUS_UPDATED = "order_status_updated",
  ORDER_UPDATED = "order_updated",
  DELIVERY_LOCATION_CHANGE = "delivery_location_change"
}

export enum OrderStatus {
  CREATED = "CREATED",
  PENDING = "PENDING", //Order created but is unprocessed
  SEARCHING_FOR_DELIVERY = "SEARCHING_FOR_DELIVERY", //waiting for delivery
  WAITING_DELIVERY_PICKUP = "WAITING_DELIVERY_PICKUP", //waiting delivery pick up
  DELIVERY_PICKEDUP = "DELIVERY_PICKEDUP", //deliver picked up
  COMPLETED = "COMPLETED", //order completed
  WAITING_CODE = "WAITING_CODE", // waiting for the code
  CANCELED = "CANCELLED", // no delivery found
  CANCELED_BY_DELIVERY = "CANCELLED_BY_DELIVERY", // motoboy 
  CANCELED_BY_STORE= "CANCELLED_BY_STORE", // store manually cancel
  APPROVED = "APPROVED", // order approved
}


export interface ServerEventData {
  event: EventTypeEnum | OrderStatus,
  data?: any
}

@Component({
  selector: 'app-track',
  standalone: true,
  imports: [
    OrderCardComponent,
    CommonModule,
    RouterLink
  ],
  templateUrl: './track.component.html',
  styleUrl: './track.component.scss',
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class TrackComponent implements AfterViewInit, OnInit {
  map: any;
  @ViewChild('map', { static: true }) mapElement!: ElementRef;
  orderListenUuid: string = "";
  deliveryMarker: any = null;
  private currentListeningOrder: OrderTrack | null = null;

  constructor(
    // private socketService: SocketService
    private api: ApiService,
    private utility: UtilityService,
    private zone: NgZone
  ) { }

  ngAfterViewInit() {
    this.startMap();
  }

  ngOnInit(): void {
    this.getOrders();
  }

  startMap() {
    const initialPosition = {
      lat: -22.9819427,
      lng: -43.2203723,
    }

    const mapOptions = {
      center: initialPosition,
      zoom: 14,
      mapTypeControl: false,
      fullscreenControl: false,
      clickableIcons: false,
      streetViewControl: false
    }

    this.map = new google.maps.Map(this.mapElement.nativeElement, mapOptions);
  }

  directionsService = new google.maps.DirectionsService();
  directionsRenderer = new google.maps.DirectionsRenderer({
    polylineOptions: {
      strokeColor: 'black'
    }
  });


  onDeliveryLocationChange = (data: OnDeliveryLocationChangeEvent) => {
    const {lat, lng, uuid} = data;

    if(uuid !== this.orderListenUuid){
      return;
    }

    // escutando o pedido
    if(this.deliveryMarker){
      var latlng = new google.maps.LatLng(Number(lat), Number(lng));

      this.deliveryMarker.setPosition(latlng)
      return;
    };

    this.deliveryMarker = new google.maps.Marker({
      position: {
        lat: Number(lat),
        lng: Number(lng)
      },
      icon: "https://i.imgur.com/WsUKKdk.png",
      map: this.map,
    });
  }

  onServerSendEvent = (eventData: ServerEventData) => {
    if(eventData.event === EventTypeEnum.DELIVERY_LOCATION_CHANGE){
      this.onDeliveryLocationChange(eventData.data)
    }

    if(eventData.event === EventTypeEnum.STATUS_UPDATED){
      this.updateOrder(eventData.data.uuid, {
        status: eventData.data.status
      })

      if(eventData.data.delivery){
        this.updateOrder(eventData.data.uuid, {
          delivery: eventData.data.delivery
        })
      }
    }
  }

  listen(order: OrderTrack){
    // console.log("LISTENING ORDER " + order.uuid)
    if(this.currentListeningOrder){
      // turn off listener
    }
    Socket.off(order.uuid, this.onServerSendEvent);
    Socket.on(order.uuid, this.onServerSendEvent);
  }

  setDirections(order: OrderTrack) {
    this.deliveryMarker?.setMap(null);
    this.handleSelection(order);
    this.directionsRenderer.setMap(this.map);

    let direction!: Direction;

    if(
      order.status === 'CREATED' || 
      order.status === 'PENDING' || 
      order.status === 'SEARCHING_FOR_DELIVERY' || 
      order.status === 'CANCELLED' || 
      order.status === 'CANCELLED_BY_DELIVERY' || 
      order.status === 'CANCELLED_BY_STORE' || 
      order.status === 'COMPLETED' ||
      order.status === "WAITING_DELIVERY_PICKUP"
    ){
      //store to customer
      direction = {
        origin: {
          lat: parseFloat(order.from.latitude),
          lng: parseFloat(order.from.longitude)
        },
        destination: {
          lat: parseFloat(order.to.latitude),
          lng: parseFloat(order.to.longitude)
        },
        travelMode: google.maps.TravelMode.DRIVING
      }
    }
    else if(order.status === 'DELIVERY_PICKED_UP' || order.status === 'WAITING_CODE'){
      //delivery to customer
      direction = {
        origin: {
          lat: parseFloat(order.from.latitude),
          lng: parseFloat(order.from.longitude)
        },
        destination: {
          lat: parseFloat(order.to.latitude),
          lng: parseFloat(order.to.longitude)
        },
        travelMode: google.maps.TravelMode.DRIVING
      }
    }

    this.directionsService.route(direction, (result: any, status: any) => {
      if (status == 'OK') {
        this.directionsRenderer.setDirections(result);
      }
    });
  }

  updateOrder(uuid: string, data: Partial<OrderTrack>){
    this.zone.run(() => {
      this.data.orders = this.data.orders.map((order: OrderTrack) => {
        if(order.uuid === uuid){
          return order = {
            ...order,
            ...data
          }
        }
        
        return order;
      });
    });
  }

  handleSelection(order: OrderTrack) {
    this.data.orders.forEach((order: OrderTrack) => {
      order.selected = false;
    });

    this.dataBkp.orders.forEach((order: OrderTrack) => {
      order.selected = false;
    });

    this.orderListenUuid = order.uuid;
    order.selected = true;
  }

  filter(event: any) {
    const value = event.target.value;
    this.data.orders = this.dataBkp.orders.filter((order: OrderTrack) => {
      return order.customer.document?.includes(value) || order.trackCode.includes(value);
    });
  }

  getOrders(){
    let currentStoreId = this.utility.getCurrentStore()._id;
    this.api.get('/stores/' + currentStoreId + '/orders').subscribe({
      next: (response: any) => {
        if(response.status){
          this.data.orders = response.data.map((order: any) => {
            this.listen(order);
             return {
              id: order._id,
              uuid: order.uuid,
              orderCode: order.trackCode,
              trackCode: order.trackCode,
              status: order.status,
              date: order.createdAt,
              selected: false,
              customer: {
                name: order.client.name,
                phone: order.client.phoneNumber,
                document: order.client.document
              },
              store: {
                name: order.store.name,
                phone: '',
              },
              delivery: {
                name: '',
                phone: '',
                latitude: '',
                longitude: ''
              },
              from: {
                street: order.address.store.street,
                number: order.address.store.number,
                neighborhood: '',
                city: order.address.store.city,
                state: order.address.store.state,
                postalCode: order.address.store.zipCode,
                latitude: order.address.store.lat,
                longitude: order.address.store.long,
              },
              to: {
                street: order.address.customer.street,
                number: order.address.customer.number,
                neighborhood: '',
                city: order.address.customer.city,
                state: order.address.customer.state,
                postalCode: order.address.customer.zipCode,
                latitude: order.address.customer.lat,
                longitude: order.address.customer.long,
              }
             }
          });
          this.dataBkp.orders = this.data.orders;
        }
      },
      error: (error: any) => {
        console.log(error);
      }
    });
  }

  data: any = {
    orders: []
  };

  dataBkp = {
    orders: []
  };


  // data = {
  //   orders: [
  //     {
  //       id: 1,
  //       orderCode: '4445',
  //       trackCode: 'lojaz_4445548817175',
  //       status: 'Em andamento',
  //       date: '2024-06-28',
  //       customer: {
  //         name: 'lucas davi',
  //         phone: '15998107009',
  //       },
  //       store: {
  //         name: 'loja teste',
  //         phone: '21966601729',
  //       },
  //       delivery: {
  //         name: 'weslley araujo',
  //         phone: '5555555555',
  //       },
  //       from: {
  //         street: 'park shopping',
  //         number: '123',
  //         neighborhood: 'Campo Grande',
  //         city: 'Rio de Janeiro',
  //         state: 'Rio de Janeiro',
  //         postalCode: '98765-432',
  //         latitude: '-22.9857594',
  //         longitude: '-43.2059509',
  //       },
  //       to: {
  //         street: 'estrada do monteiro',
  //         number: '123',
  //         neighborhood: 'Campo Grande',
  //         city: 'Rio de Janeiro',
  //         state: 'Rio de Janeiro',
  //         postalCode: '54321-876',
  //         latitude: '-22.9829097',
  //         longitude: '-43.2042848',
  //       },
  //     },
  //     {
  //       id: 2,
  //       orderCode: '4446',
  //       trackCode: 'lojaz_4445548817176',
  //       status: 'Completo',
  //       date: '2024-06-28',
  //       customer: {
  //         name: 'lucas davi',
  //         phone: '15998107009',
  //       },
  //       store: {
  //         name: 'loja teste',
  //         phone: '21966601729',
  //       },
  //       delivery: {
  //         name: 'weslley araujo',
  //         phone: '5555555555',
  //       },
  //       from: {
  //         street: 'park shopping',
  //         number: '123',
  //         neighborhood: 'Campo Grande',
  //         city: 'Rio de Janeiro',
  //         state: 'Rio de Janeiro',
  //         postalCode: '98765-432',
  //         latitude: '-22.9857694',
  //         longitude: '-43.2089609',
  //       },
  //       to: {
  //         street: 'estrada do monteiro',
  //         number: '123',
  //         neighborhood: 'Campo Grande',
  //         city: 'Rio de Janeiro',
  //         state: 'Rio de Janeiro',
  //         postalCode: '54321-876',
  //         latitude: '-22.9329097',
  //         longitude: '-43.2042848',
  //       },
  //     },
  //     {
  //       id: 3,
  //       orderCode: '4447',
  //       trackCode: 'lojaz_4445548817177',
  //       status: 'Cancelado',
  //       date: '2024-06-28',
  //       customer: {
  //         name: 'lucas davi',
  //         phone: '15998107009',
  //       },
  //       store: {
  //         name: 'loja teste',
  //         phone: '21966601729',
  //       },
  //       delivery: {
  //         name: 'weslley araujo',
  //         phone: '5555555555',
  //       },
  //       from: {
  //         street: 'park shopping',
  //         number: '123',
  //         neighborhood: 'Campo Grande',
  //         city: 'Rio de Janeiro',
  //         state: 'Rio de Janeiro',
  //         postalCode: '98765-432',
  //         latitude: '-22.9857494',
  //         longitude: '-43.2089409',
  //       },
  //       to: {
  //         street: 'estrada do monteiro',
  //         number: '123',
  //         neighborhood: 'Campo Grande',
  //         city: 'Rio de Janeiro',
  //         state: 'Rio de Janeiro',
  //         postalCode: '54321-876',
  //         latitude: '-22.9829097',
  //         longitude: '-43.2042848',
  //       },
  //     },
  //   ]
  // };
}
/*

export interface OrderTrack {
  id: number,
  orderCode: string,
  trackCode: string,
  status: string,
  date: string,
  customer: OrderCustomer,
  store: OrderStore,
  delivery: OrderDelivery,
  from: OrderAddress,
  to: OrderAddress
}

export interface OrderCustomer {
  id: number,
  name: string,
  phone: string,
}

export interface OrderDelivery {
  id: number,
  name: string,
  phone: string,
}

export interface OrderAddress {
  id: number,
  street: string,
  number?: string,
  neighborhood?: string,
  city?: string,
  state?: string,
  postalCode: string,
  latitude: string,
  longitude: string
}

export interface OrderStore {
  id: number,
  name: string,
  phone: string,
}
*/