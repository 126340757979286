<div class="container">
    <div class="header">
        <div class="table-title">
            {{data.metadata.title}}
        </div>
        <div class="options">
            <!-- <input type="text" [placeholder]="data.metadata.placeholder"> -->
        </div>
    </div>

    <div class="table-container">
        <table>
            <thead>
                <tr>
                    @for(header of data.metadata.headers; track $index){
                    <th>{{ header.title }}</th>
                    }
                    @if(data.metadata.actions?.length > 0){
                    <th>Ações</th>
                    }
                </tr>
            </thead>
            <tbody>
                @for(item of data.data; track item.id){
                <tr>
                    @for(header of data.metadata.headers; track $index){
                    @if(item[header.key] == null || item[header.key] == ''){
                    <td (click)="handleClick(item)">-</td>
                    }
                    @else {
                    @switch (header.type) {
                    @case('status') {
                    <td (click)="handleClick(item)">
                        <div [class]="'status ' + item[header.key]">
                            {{item[header.key] | status}}
                        </div>
                    </td>
                    }
                    @case('date'){
                    <td (click)="handleClick(item)">{{ item[header.key] | timeAgo }}</td>
                    }
                    @case('currency'){
                    <td (click)="handleClick(item)">{{ item[header.key] | brl }}</td>
                    }
                    @case('in/out'){
                    <td (click)="handleClick(item)">{{ item[header.key] | inOut }}</td>
                    }
                    @case('link'){
                        <!-- {{header.base_url}} -->
                    <td (click)="handleClick(item)">
                        <a [href]="((header.base_url || '') + item[header.key] + (header.token ? '?token=' + header.token : ''))" target="_blank"> 
                            {{ header.link_message || ((header.base_url || '') + item[header.key]) }}
                        </a>
                        }
                        @default {
                    <td (click)="handleClick(item)">{{ item[header.key] }}</td>
                    }
                    }

                    }
                    }

                    @if(data.metadata.actions?.length > 0){
                    <td class="actions">
                        <div class="actions-container">
                            @for(action of data.metadata.actions; track $index){
                                <button (click)="handleAction(action.action, item)" [class]="action.color">
                                    <img [src]="'../../../../assets/icons/' + action.icon + '.svg'" alt="">
                                </button>
                            }
                        </div>
                    </td>
                    }
                </tr>
                }
            </tbody>
        </table>
    </div>

    <div class="footer">
        <div class="total">
            Registros: {{data.pagination.totalItems}}
        </div>

        <div class="paginator">
            <button [disabled]="data.pagination.page <= 1" class="btn"
                (click)="handlePagination(data.pagination.page - 1)">&lt;</button>
            <div class="current">
                {{data.pagination.page}}
            </div>
            <button [disabled]="data.pagination.page >= data.pagination.totalPages" class="btn"
                (click)="handlePagination(data.pagination.page + 1)">&gt;</button>
        </div>
    </div>
</div>