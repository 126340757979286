<div class="sub-route-title">
    Informações da loja
</div>

<div class="content">
    <div class="form">
        <div class="input-container">
            <label for="name">Nome</label>
            <input type="text" id="name" placeholder="Nome da loja" />
        </div>
        <div class="input-container">
            <label for="document">CPF/CNPJ</label>
            <input type="text" id="document" placeholder="CPF/CNPJ" />
        </div>
        <div class="input-container">
            <label for="address">Endereço</label>
            <input type="text" id="address" placeholder="Endereço" />
        </div>
        <div class="input-container">
            <label for="website">Website</label>
            <input type="text" id="website" placeholder="ex: www.lojazdelivery.com.br" />
        </div>
    </div>
    <div class="options">
        <button class="btn exclusion">Solicitar exclusão</button>
        <button class="btn save">Salvar</button>
    </div>
</div>