import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { UtilityService } from './utility';
import { BehaviorSubject } from 'rxjs';

interface Wallet {
  balance: string;
  pendingBalance: string;
}

@Injectable({
  providedIn: 'root'
})
export class WalletService {
  private wallet = new BehaviorSubject<Wallet>({
    balance: '0,00',
    pendingBalance: '0,00'
  });

  constructor(
    private api: ApiService,
  ){
    const data = JSON.parse(localStorage.getItem('currentStore') || '{}');
    if(data.wallet){
      this.wallet.next(data.wallet);
    }
  }

  getWallet(){
    return this.wallet.asObservable();
  }

  setWallet(wallet: Wallet){
    this.wallet.next(wallet);

    const data = JSON.parse(localStorage.getItem('currentStore') || '{}');
    data.wallet = wallet;
    localStorage.setItem('currentStore', JSON.stringify(data));
    // console.log('ATUALIZANDOOOOOOOOOOOO EU NÃO AGUENTO MAIS', wallet);
  }

  async syncWallet(){
    this.api.get(`/stores/${this.getCurrentStore()._id}/wallet`).subscribe({
      next: (response: any) => {
        const wallet = {
          balance: response.data.balance || '0,00',
          pendingBalance: response.data.blockedBalance || '0,00'
        };
        this.setWallet(wallet);
      },
      error: (error) => {
        console.error(error);
      }
    });
  }

  public getCurrentStore(){
    return JSON.parse(localStorage.getItem('currentStore') || '{}');
  }
}


/* response
{
  "_id": "66ab171e9ff6a6b920568637",
  "storeId": "66ab171e9ff6a6b920568635",
  "balance": "0,00",
  "pendingBalance": "1.900,00",
  "blockedBalance": "0,00",
  "createdAt": "2024-08-01T04:55:35.512Z",
  "updatedAt": "2024-08-01T04:55:35.512Z",
  "__v": 0
}
*/