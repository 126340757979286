import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../../../services/api.service';
import { ToastrService } from 'ngx-toastr';
import { Plan } from '../../../../types/models';
import { CommonModule } from '@angular/common';
import { UtilityService } from '../../../../services/utility';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../../../../services/auth.service';

@Component({
  selector: 'app-select-plan',
  standalone: true,
  imports: [
    CommonModule,
  ],
  templateUrl: './select-plan.component.html',
  styleUrl: './select-plan.component.scss'
})
export class SelectPlanComponent implements OnInit {
  plans: Plan[] = [];
  planSelected: Plan | null = null;

  constructor(
    private api: ApiService,
    private toastr: ToastrService,
    private utility: UtilityService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private auth: AuthService
  ){
    this.activatedRoute.params.subscribe({
      next: () => {
        if(!this.auth.isAuthenticated()){
          this.router.navigate(['auth/signin']);
        }
      },
      error: () => {
        this.router.navigate(['']);
      }
    })
  }

  ngOnInit(): void {
    this.loadPlans();
  }

  loadPlans(){
    this.api.get('/plans').subscribe({
      next: (response: any) => {
        this.plans = response.data;
        this.plans.forEach(p => p.selected = false);
      },
      error: () => {
        this.toastr.show('Falha ao obter planos. Tente novamente mais tarde.');
      }
    })
  }

  setPlan(plan: Plan){
    this.plans.forEach(p => p.selected = false);
    plan.selected = true;
    this.planSelected = plan;
    // console.log(this.planSelected);
  }

  assignPlan(){
    const currentStore = this.utility.getCurrentStore(); 
    const currentPlan: any = this.planSelected;

    // console.log(currentStore._id); 
    this.api.post(`/stores/${currentStore._id}/plan`, {planId: currentPlan._id}).subscribe({
      next: (response) => {
        // console.log(response);
        this.toastr.show('Plano adicionado com sucesso!');
        this.utility.setPlanId(currentPlan._id);
        this.router.navigate(['/track']);
      },
      error: () => {
        this.toastr.show('Falha ao atribuir plano. Tente novamente mais tarde.');
      }
    });
  }
}

/*
{
     "data": [
        {
            "_id": "669e6ab68329630091591986",
            "name": "Plano Básico",
            "amountPercentageOverProduct": "0",
            "amountMonthly": "0",
            "advantages": [
                {
                    "code": "BUSINESS_USER",
                    "name": "Usuários de acesso",
                    "value": 5,
                    "available": true,
                    "beta": false,
                    "_id": "669e6ab68329630091591987"
                }
            ],
            "createdAt": "2024-07-22T14:20:35.674Z",
            "updatedAt": "2024-07-22T14:20:35.674Z",
            "__v": 0
        },
        {
            "_id": "66a15f4307ae2e598a71d9ba",
            "name": "Plano DEV",
            "amountPercentageOverProduct": "0",
            "amountMonthly": "0",
            "advantages": [
                {
                    "code": "BUSINESS_USER",
                    "name": "Usuários de dev",
                    "value": 5,
                    "available": true,
                    "beta": false,
                    "_id": "66a15f4307ae2e598a71d9bb"
                }
            ],
            "createdAt": "2024-07-24T13:48:25.171Z",
            "updatedAt": "2024-07-24T13:48:25.171Z",
            "__v": 0
        }
    ]
}
*/