import { AfterViewInit, Component, ElementRef, NgZone, ViewChild } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { ApiService } from '../../../../services/api.service';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { CommonModule } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { UtilityService } from '../../../../services/utility';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { AuthService } from '../../../../services/auth.service';

declare const google: any;

@Component({
  selector: 'app-new-store',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    MatProgressSpinner,
    CommonModule,
    NgxMaskDirective,
    NgxMaskPipe
  ],
  providers: [
    provideNgxMask()
  ],
  templateUrl: './new-store.component.html',
  styleUrl: './new-store.component.scss'
})
export class NewStoreComponent implements AfterViewInit {
  @ViewChild('search', { static: false }) searchElement!: ElementRef;
  loading: boolean = false;

  constructor(
    private api: ApiService,
    private toastr: ToastrService,
    private router: Router,
    private utility: UtilityService,
    private activatedRoute: ActivatedRoute,
    private auth: AuthService,
    private zone: NgZone
  ) {
    this.activatedRoute.params.subscribe({
      next: () => {
        if(!this.auth.isAuthenticated()){
          this.router.navigate(['auth/signin']);
        }
      },
      error: () => {
        this.router.navigate(['']);
      }
    })
   }

  form = new FormGroup({
    name: new FormControl('', [Validators.minLength(4), Validators.required]),
    document: new FormControl('', [Validators.required]),
    address: new FormControl('', [Validators.required]),
    phoneNumber: new FormControl('', [Validators.required]),
    postalCode: new FormControl(''),
    city: new FormControl(''),
    state: new FormControl(''),
    latitude: new FormControl('', Validators.required),
    longitude: new FormControl('', Validators.required),
  })

  ngAfterViewInit(): void {
    this.checkAndStartAutocomplete();
  }

  checkAndStartAutocomplete() {
    setTimeout(() => {
      if (this.searchElement && this.searchElement.nativeElement) {
        this.startAutocomplete();
      } else {
        this.checkAndStartAutocomplete();
      }
    }, 500);
  }

  startAutocomplete() {
    const autocomplete = new google.maps.places.Autocomplete((this.searchElement.nativeElement as HTMLInputElement), {
      types: ['geocode'],
      componentRestrictions: { country: 'BR' },
    });

    autocomplete.addListener('place_changed', () => {
      const place = autocomplete.getPlace();
      this.form.get('address')?.setValue(place.formatted_address);
      this.form.get('latitude')?.setValue(place.geometry.location.lat());
      this.form.get('longitude')?.setValue(place.geometry.location.lng());

      this.zone.run(() => {
        this.form.updateValueAndValidity();
      })
    });
  }

  handleRegister() {
    this.loading = true;

    const store = {
      name: this.form.get('name')?.value,
      document: this.form.get('document')?.value,
      phoneNumber: this.form.get('phoneNumber')?.value,
      address: {
        latitude: this.form.get('latitude')?.value?.toString(),
        longitude: this.form.get('longitude')?.value?.toString(),
        address: this.form.get('address')?.value,
      }
    }

    console.log(store);

    this.api.post('/stores', store).subscribe({
      next: (response: any) => {
        if (response.status) {
          this.toastr.show('Loja cadastrada com sucesso!');
          this.utility.hasStore();
          this.router.navigate(['/store/select']);
          this.loading = false;
        }
        else {
          this.toastr.show(response.message);
          this.loading = false;
        }
      },
      error: () => {
        this.toastr.show('Erro ao cadastrar loja');
        this.loading = false
      }
    });
  }
}