import { Component, Input } from '@angular/core';
import { Notification } from '../../../types/models';

@Component({
  selector: 'app-notification-card',
  standalone: true,
  imports: [],
  templateUrl: './notification-card.component.html',
  styleUrl: './notification-card.component.scss'
})
export class NotificationCardComponent {
  @Input() notification!: Notification;
}
