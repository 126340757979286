<div class="title">
    Pedidos
</div>

<div class="content">
    @if(!loadingOrders){
        <app-generic-table [data]="orders"></app-generic-table>
    }

    <div class="float-button" [routerLink]="['/new-order']">
        +
    </div>
</div>