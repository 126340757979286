import { Component, Input } from '@angular/core';
import { CreditCard } from '../../../types/models';

@Component({
  selector: 'app-credit-card',
  standalone: true,
  imports: [],
  templateUrl: './credit-card.component.html',
  styleUrl: './credit-card.component.scss'
})
export class CreditCardComponent {
  @Input() creditCard!: CreditCard;
}
