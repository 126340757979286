<!-- <div *ngFor="let menu of menus">
    {{menu.title}}
    <div *ngFor="let submenu of menu.children">
        ---- {{submenu.title}}
    </div>
</div> -->

<div class="content">
    <div class="side-menu">
        <div class="logo">
            <img src="../../../assets/icons/logo.svg" width="60" alt="Logo">
        </div>

        <div class="menus">
            <div class="menu-item" *ngFor="let menu of menus">
                <div class="menu-title-container" [routerLink]="menu.route" [routerLinkActive]="'active'">
                    <div class="icon">
                        <!-- <img [src]="menu.icon" width="20" alt="Icon"> -->
                        <img [src]="menu.icon" width="20" alt="Icon">
                    </div>
                    <div class="menu-title">
                        {{menu.title}}
                    </div>
                </div>

                <div class="submenus" *ngFor="let submenu of menu.children">
                    <div class="submenu-item" [routerLink]="menu.route">
                        • {{submenu.title}}
                    </div>
                </div>
            </div>
        </div>

    </div>

    <div class="main-content">
        <div class="content-position">
            <!-- title -->
            <router-outlet></router-outlet>
        </div>
    </div>

</div>

