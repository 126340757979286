<div class="auth-container">
    <div class="auth-content">
        <div class="auth-logo-container">
            <img src="../../../../assets/icons/logo.svg" alt="">
        </div>

        <div class="auth-form-container">
            <h1>Escolha um plano para a sua loja</h1>

            <div class="auth-form">
                <div class="auth-form-group" style="gap: 24px;">
                    <div (click)="setPlan(plan)" *ngFor="let plan of plans" class="plan-card">
                        <div class="plan-card-body">
                            <div class="name-container">
                                <p>{{plan.name}}</p>
                            </div>
                            <div *ngIf="plan.selected" class="selected-container">
                                <p>Selecionado</p>
                            </div>

                            <div class="left-side">
                                {{plan?.description || 'Sem descrição'}}
                            </div>

                            <div class="right-side">
                                <div class="column1">
                                    {{plan.amountPercentageOverProduct}}% sobre vendas
                                </div>
                                <div class="column2">
                                    <!-- <div *ngFor="plan.adv" class="item">

                                    </div> -->
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="auth-form-group">
                        <button [disabled]="!planSelected" (click)="assignPlan()" class="auth-btn">
                            <p>Confirmar</p>
                        </button>
                    </div>
                </div>  
            </div>
        </div>
    </div>
</div>
