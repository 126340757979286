import { Component } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { ApiService } from '../../../../services/api.service';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from '../../../../services/auth.service';

@Component({
  selector: 'app-signin',
  standalone: true,
  imports: [
    RouterLink,
    ReactiveFormsModule
  ],
  templateUrl: './signin.component.html',
  styleUrl: './signin.component.scss'
})
export class SigninComponent {
  loading: boolean = false;
  showPassword: boolean = false;

  form = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email]),
    password: new FormControl('', [Validators.required, Validators.minLength(6)]),
    type: new FormControl('user')
  });

  constructor(
    private router: Router,
    private auth: AuthService,
    private activatedRoute: ActivatedRoute
  ) {
    let role = 'user';

    this.activatedRoute.params.subscribe({
      next: (params: any) => {
        if (params.role === 'admin') {
          role = params.role;
        }
        if (this.auth.isAuthenticated()) {
          this.router.navigate(['/store/select']);
        }
      }
    })

    console.log(this.form.get('type'));
    this.form.get('type')!.setValue(role);
    console.log(this.form.get('type'));
  }

  handleLogin() {
    this.loading = true;
    const email = this.form.value.email;
    const password = this.form.value.password;
    const type = this.form.value.type

    this.auth.login(email!, password!, type!).then(() => {
      this.loading = false;
    },
      () => {
        this.loading = false;
      }
    );
  }
}
