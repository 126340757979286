import { Component, Inject, NgZone, OnInit, inject } from '@angular/core';
import { Router } from '@angular/router';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PixDeposit } from '../../../types/payment/pix';
import { FormsModule } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';

@Component({
    selector: 'app-pix-dialog',
    standalone: true,
    imports: [
        FormsModule
    ],
    templateUrl: './pix-dialog.html',
    styleUrl: './pix-dialog.scss'
})
export class PixDialogComponent implements OnInit {
    readonly dialogRef = inject(MatDialogRef<PixDialogComponent>);
    pix: PixDeposit | undefined;
    expireSeconds: string = '';
    expired: boolean = false;
    copied: boolean = false;

    ngOnInit(): void {
        this.expireSeconds = this.getExpireDate(this.pix?.expiresDate);
        const interval = setInterval(() => {
            this.expireSeconds = this.getExpireDate(this.pix?.expiresDate);
            if (parseInt(this.expireSeconds) <= 0) {
                this.expired = true;
                this.getStatus();

                this.toastr.show('O código expirou.');
                this.dialogRef.close();
                clearInterval(interval);
            }
        }, 1000);
    }

    constructor(
        private router: Router,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private toastr: ToastrService,
    ) {
        this.pix = this.data.pix;
    }

    closeDialog() {
        this.dialogRef.close();
    }

    copyToClipboard(barCode: string) {
        navigator.clipboard.writeText(barCode);
        this.toastr.show('Código copiado para a área de transferência');
        this.copied = true;

        setTimeout(() => {
            this.copied = false;
        }, 3000);

        console.log(this.copied);
    }

    openExternalLink(url: string) {
        window.open(url, '_blank');
    }

    getExpireDate(expiresDate: any): string {
        const expirationDate = new Date(expiresDate);
        const currentDate = new Date();
        const secondsRemaining = Math.floor((expirationDate.getTime() - currentDate.getTime()) / 1000);

        return secondsRemaining.toString();
    }

    getStatus() {
        if (!this.expired) {
            return 'Aguardando pagamento.'
        }
        else {
            return 'O código expirou.'
        }
    }
}