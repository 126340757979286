import { Component, Input } from '@angular/core';
import { WeeklySummary } from '../../../../types/models';
import { CommonModule } from '@angular/common';
import { BaseChartDirective } from 'ng2-charts';
import { ChartData, ChartOptions } from 'chart.js';

@Component({
  selector: 'app-weekly-summary',
  standalone: true,
  imports: [
    CommonModule,
    BaseChartDirective,
  ],
  templateUrl: './weekly-summary.component.html',
  styleUrl: './weekly-summary.component.scss'
})
export class WeeklySummaryComponent {
  @Input() data!: WeeklySummary[];

  public lineChartData: ChartData<'line'> = {
    datasets: [
      {
        data: [], // to be filled with counts
        label: 'Weekly Summary',
        fill: false,
      }
    ],
    labels: [] // to be filled with dates
  };
  public lineChartOptions: ChartOptions = {
    responsive: true,
  };

  constructor() {
    this.initializeChartData();
  }

  initializeChartData() {
    const data = {
      "weekly_summary": [
        { "date": "26-06-24", "count": "5" },
        { "date": "25-06-24", "count": "2" },
        { "date": "24-06-24", "count": "1" },
        { "date": "23-06-24", "count": "9" },
        { "date": "22-06-24", "count": "7" },
        { "date": "21-06-24", "count": "4" },
        { "date": "20-06-24", "count": "4" },
      ]
    };

    this.lineChartData.labels = data.weekly_summary.map(item => item.date);
    this.lineChartData.datasets[0].data = data.weekly_summary.map(item => +item.count);
  }
}
