<div class="sub-route-title">
    Usuários
</div>

<mat-table [dataSource]="users">
    <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef>Nome</mat-header-cell>
        <mat-cell *matCellDef="let user"> {{user.name}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="email">
        <mat-header-cell *matHeaderCellDef>Email</mat-header-cell>
        <mat-cell *matCellDef="let user"> {{user.email}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="role">
        <mat-header-cell *matHeaderCellDef>Perfil</mat-header-cell>
        <mat-cell *matCellDef="let user"> {{user.role}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="addedBy">
        <mat-header-cell *matHeaderCellDef>Adicionado por</mat-header-cell>
        <mat-cell *matCellDef="let user"> {{user.addedBy}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="createdAt">
        <mat-header-cell *matHeaderCellDef>Criado em</mat-header-cell>
        <mat-cell *matCellDef="let user"> {{user.createdAt}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="action">
        <mat-header-cell *matHeaderCellDef>Ação</mat-header-cell>
        <mat-cell *matCellDef="let user"> <button> Editar </button> </mat-cell>
    </ng-container>


    <mat-header-row *matHeaderRowDef="['name', 'email', 'role', 'addedBy', 'createdAt', 'action']"></mat-header-row>
    <mat-row *matRowDef="let user; columns: ['name', 'email', 'role', 'addedBy', 'createdAt', 'action']"></mat-row>
</mat-table>