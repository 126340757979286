import { Component, inject } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ApiService } from '../../../services/api.service';
import { UtilityService } from '../../../services/utility';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { PixDeposit } from '../../../types/payment/pix';
import { MatDialogClose, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-deposit-dialog',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    FormsModule,
    MatDialogClose
  ],
  templateUrl: './deposit-dialog.html',
  styleUrl: './deposit-dialog.scss'
})
export class DepositDialogComponent {
    readonly dialogRef = inject(MatDialogRef<DepositDialogComponent>);

    amount!: number;
    paymentMethod = 'PIX';
    loading: boolean = false;
    pix: PixDeposit | undefined;

    constructor(
        private api: ApiService,
        private utility: UtilityService,
        private toastr: ToastrService,
        private router: Router
    ) { }

    setAmount(amount: number) {
        this.amount = amount;
    }

    requestDeposit(amount: number, paymentMethod: string) {
        this.dialogRef.close({ amount, paymentMethod });
    }

    closeDialog() {
        this.dialogRef.close();
    }    
}