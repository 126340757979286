<div class="container">
    <div class="header">
        <div class="origin"
            [style.background]="notification.priority == 'low'? 'gray' : notification.priority == 'medium'? '#498FF9' : '#FE6A6A'">
            {{notification.origin}}
        </div>
        <div class="date">
            {{notification.date}}
        </div>
    </div>

    <div class="body">
        <div class="notification-title">
            {{notification.title}}
        </div>
        <div class="description">
            {{notification.description}}
        </div>
    </div>
</div>