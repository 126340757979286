import { Component } from '@angular/core';
import { CreditCard } from '../../../types/models';
import { CreditCardComponent } from '../../../components/dashboard/credit-card/credit-card.component';
import { CommonModule } from '@angular/common';
import {
  MatDialog
} from '@angular/material/dialog';
import { DepositDialogComponent } from '../../../components/modals/deposit-dialog/deposit-dialog.component';
import { PixDeposit } from '../../../types/payment/pix';
import { ApiService } from '../../../services/api.service';
import { ToastrService } from 'ngx-toastr';
import { UtilityService } from '../../../services/utility';
import { PixDialogComponent } from '../../../components/modals/pix-payment-dialog/pix-dialog.component';
import { GenericTableComponent } from '../../../components/dashboard/generic-table/generic-table.component';
import { WalletService } from '../../../services/wallet.service';
import { BrlPipe } from '../../../pipes/brl.pipe';

@Component({
  selector: 'app-payments',
  standalone: true,
  imports: [
    CreditCardComponent,
    CommonModule,
    GenericTableComponent,
    BrlPipe
  ],
  templateUrl: './payments.component.html',
  styleUrl: './payments.component.scss'
})
export class PaymentsComponent {
  transactions!: any;
  transactionsAvailable: boolean = false;

  data = {
    wallet: {
      balance: "0",
      runningBalance: "0",
      color: {
        primary: '#373636',
        secondary: '#000000'
      },
    },
    creditCards: [
      // {
      //   lastFourDigits: '1234',
      //   networkIcon: 'https://painel.lojaz.com.br/assets/credit_card_network_icons/mastercard.svg',
      //   color: {
      //     primary: '#373636',
      //     secondary: '#000000'
      //   },
      //   cardName: 'Meu cartão virtual 1',
      //   ownerName: 'João da Silva'
      // },
      // {
      //   lastFourDigits: '5678',
      //   networkIcon: 'https://painel.lojaz.com.br/assets/credit_card_network_icons/mastercard.svg',
      //   color: {
      //     primary: '#8C00FB',
      //     secondary: '#482B88'
      //   },
      //   cardName: 'Meu cartão virtual 2',
      //   ownerName: 'Maria da Silva'
      // }
    ]
  }

  constructor(
    public dialog: MatDialog,
    private api: ApiService,
    private toastr: ToastrService,
    private utility: UtilityService,
    private walletService: WalletService
  ) { 
    this.walletService.getWallet().subscribe({
      next: (wallet) => {
        this.data.wallet.balance = wallet.balance;
        this.data.wallet.runningBalance = wallet.pendingBalance;
      }
    });
  }

  ngOnInit() {
    this.loadTransactions();
  }

  openDepositModal(event: any) {
    //prevent propagation
    event.stopPropagation();

    let dialogRef = this.dialog.open(DepositDialogComponent);

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.api.post(`/stores/${this.utility.getCurrentStore()._id}/wallet/movimentations`, result).subscribe({
          next: (response: any) => {
            this.toastr.show(response.message);

            if (result.paymentMethod === 'PIX' && response.status == true) {
                let pixData: PixDeposit = {
                ...response.data,
                brCode: response.data.barCode
                };

              let dialogRef = this.dialog.open(PixDialogComponent, {
                data: {
                  pix: pixData
                }
              });

              this.loadTransactions();
            }
          },
          error: () => {
            this.toastr.show('Erro ao realizar a operação', 'Erro');
          }
        });
      }
    });
  }

  addNewCard() {
    this.toastr.show('Funcionalidade temporariamente indisponível', 'Aviso');
  }

  async loadTransactions(page: number = 1) {
    this.api.get(`/stores/${this.utility.getCurrentStore()._id}/wallet/movimentations?limit=4&page=${page}`).subscribe({
      next: (response: any) => {
        this.transactions = response;
        this.transactions['metadata'] = this.getMetadata();
        this.transactionsAvailable = true;
      },
      error: () => {
        this.toastr.show('Erro ao carregar as transações', 'Erro');
      }
    });
  }

  onPageChange(page: number){
    this.loadTransactions(page);
  }

  onRowClick(data: any){
    if(data.status === 'pending'){
      this.dialog.open(PixDialogComponent, {
        data: {
          pix: data.pix
        }
      });
    }
    else if(data.status === 'approved'){
      this.toastr.show('Transação já aprovada', 'Aviso');
    }

  }

  getMetadata(): any{
    return {
      placeholder: 'Pesquisar',
      title: 'Listagem de movimentações',
      rowClickAction: 'handle_payment',
      headers: [
        {
          title: 'Origem',
          key: 'origin',
          type: 'text'
        },
        {
          title: 'Tipo',
          key: 'type',
          type: 'in/out'
        },
        {
          title: 'Valor',
          key: 'amount',
          type: 'currency'
        },
        {
          title: 'Observação',
          key: 'observation',
          type: 'text'
        },
        {
          title: 'Método de Pagamento',
          key: 'paymentMethod',
          type: 'text'
        },
        {
          title: 'Status',
          key: 'status',
          type: 'status'
        },
        {
          title: 'Data',
          key: 'date',
          type: 'date'
        }
      ]
    }
  }
}

/*
export interface CreditCard {
    lastFourDigits: string;
    networkIcon: string; //ícone da bandeira
    color: {
        primary: string;
        secondary: string;
    };
    cardName: string; //nome dado ao cartão, por exemplo: "meu cartão virtual 1"
    ownerName: string; //nome do proprietário    
}
*/