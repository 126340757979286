<div class="title">
    Métodos de pagamento
</div>

<div class="container">
    <app-credit-card *ngFor="let card of data.creditCards" [creditCard]="card"></app-credit-card>

    <div class="card-container"
        [style.background]="'linear-gradient(to right, ' + data.wallet.color.primary + ', ' + data.wallet.color.secondary + ')'">
        <div class="card-content">
            <div class="card-header">
                <div class="card-title">
                    Saldo LojAZ
                </div>
                <div class="card-icon">
                    <img src="../../../../assets/icons/logo_white.svg" alt="" class="src">
                </div>
            </div>

            <div class="card-body">
                <div>
                    <div class="body-title">
                        Disponível
                    </div>
                    <div class="balance">
                        R${{data.wallet.balance}}
                    </div>
                </div>
                <div class="side-button">
                    <button class="button" (click)="openDepositModal($event)">Depositar</button>
                </div>
            </div>
        </div>
    </div>

    <div class="new-card-container" (click)="addNewCard()">
        <p>
            <span class="icon">
                <img src="../../../../assets/icons/plus.svg">
            </span>
            Adicionar novo cartão
        </p>
    </div>

    <div class="info-container">
        <div class="header">
            <div class="header-title">
                <div class="text-title">Próximos débitos</div>
                <div class="icon">
                    <img src="../../../../assets/icons/attention.svg" alt="" class="src">
                </div>
            </div>
            <div class="subtitle">Entregas em progresso</div>
        </div>

        <div class="body">
            R$ {{data.wallet.runningBalance}}
        </div>
    </div>
</div>

<div class="info">
    Sempre tentaremos usar seu saldo como pagamento principal, caso não possua saldo suficiente passaremos a usar seus
    cartões.
</div>

@if(transactionsAvailable){
<div class="title" style="padding-top: 18px;">
    Movimentações
</div>

<div class="transactions-container">
    <app-generic-table style="width: 100%;" [data]="transactions" (pageChange)="onPageChange($event)" (rowClick)="onRowClick($event)"></app-generic-table>
</div>
}