<div class="modal">
    <div class="modal-header">
        <div class="modal-title">
            Adicionar saldo
        </div>
        <div class="modal-close" (click)="closeDialog()">
            X
        </div>
    </div>

    <div class="modal-body">
        <div class="modal-content">
            <form>
                <span>Valor desejado:</span>
                <input type="number" id="amount" name="amount" placeholder="R$ 0,00" required [(ngModel)]="amount">

                <div class="options">
                    <div class="option" (click)="setAmount(100)">R$100,00</div>
                    <div class="option" (click)="setAmount(200)">R$200,00</div>
                    <div class="option" (click)="setAmount(500)">R$500,00</div>
                    <div class="option" (click)="setAmount(1000)">R$1.000,00</div>
                </div>

                <br>
                <span>Método de pagamento:</span>
                <select id="method" name="paymentMethod" required [ngModel]="paymentMethod">
                    <option value="PIX">Pix</option>
                </select>
            </form>
        </div>
    </div>

    <div class="modal-footer">
        <button [disabled]="amount == 0 || !amount || loading" (click)="requestDeposit(amount, paymentMethod)" class="btn btn-primary">Adicionar saldo</button>
    </div>
</div>