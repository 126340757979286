import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, ElementRef, ViewChild } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { OrderItem, OrderTrack } from '../../types/order';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from '../../services/api.service';
import { OrderModel } from '../../types/orderv2';
import { environment } from '../../../environments/environment';
import { SocketService } from '../../services/socket.service';
import { EventTypeEnum, OnDeliveryLocationChangeEvent, ServerEventData } from '../dashboard/track/track.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

declare const google: any;

interface Location {
  lat: number;
  lng: number;
}

@Component({
  selector: 'app-tracker',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    NgxMaskDirective,
    NgxMaskPipe,
    MatProgressBarModule,
    MatProgressSpinnerModule
  ],
  providers: [
    provideNgxMask()
  ],
  templateUrl: './tracker.component.html',
  styleUrl: './tracker.component.scss'
})
export class TrackerComponent implements AfterViewInit {
  @ViewChild('map', { static: true }) mapElement!: ElementRef;
  map: any;
  showForm: boolean = true;
  trackCode: string = '';
  deliveryMarker: any = null;
  loading: boolean = false;
  showCode: boolean = false;
  showLogo: boolean = true;

  markers = {
    store: {
      location: Location,
      marker: null,
    },
    delivery: {
      location: Location,
      marker: null,
    },
    customer: {
      location: Location,
      marker: null,
    }
  }

  directionsService = new google.maps.DirectionsService();
  directionsRenderer = new google.maps.DirectionsRenderer({
    polylineOptions: {
      strokeColor: 'black',
    }
  });

  constructor(
    private activatedRoute: ActivatedRoute,
    private toast: ToastrService,
    private api: ApiService,
    private socket: SocketService
  ) {
    this.activatedRoute.params.subscribe(params => {
      this.trackCode = params['code'];
      this.showLogo = params['showLogo'] !== "false";
      if(this.trackCode){
        this.track();
      }
    });

    this.activatedRoute.queryParams.subscribe(params => {
      this.showLogo = params['showLogo'] !== "false";
    });
  }
  
  order: OrderModel = {} as any;

  ngAfterViewInit(): void {
    this.startMap();
  }

  getDeliveryPicture(){
    return `${environment.baseApiUrl}/uploads/picture/${this.order.delivery?.picture}`
  }

  onDeliveryLocationChange = (data: OnDeliveryLocationChangeEvent) => {
    const {lat, lng} = data

    // escutando o pedido
    if(this.deliveryMarker){
      var latlng = new google.maps.LatLng(Number(lat), Number(lng));
      this.deliveryMarker.setPosition(latlng)
      return;
    };

    this.deliveryMarker = new google.maps.Marker({
      position: {
        lat: Number(lat),
        lng: Number(lng)
      },
      icon: "https://i.imgur.com/WsUKKdk.png",
      map: this.map,
    });
  }


  onServerSendEvent = (eventData: ServerEventData) => {
    if(eventData.event === EventTypeEnum.DELIVERY_LOCATION_CHANGE){
      this.onDeliveryLocationChange(eventData.data)
    }

    if(eventData.event === EventTypeEnum.STATUS_UPDATED){
      this.order.status = eventData.data.status;

      if(eventData.data.delivery){
        this.order.delivery = eventData.data.delivery;
      }
    }
  }

  startTracking = (trackCode: string) => {
    this.socket.on(this.order.uuid, this.onServerSendEvent)
    
    this.socket.emit("create-anonymous-track", {
      trackCode: trackCode
    })
  }

  startMap() {
    const initialPosition = {
      lat: -22.9819427,
      lng: -43.2203723,
    }

    const mapOptions = {
      center: initialPosition,
      zoom: 14,
      mapTypeControl: false,
      fullscreenControl: false,
      clickableIcons: false,
      streetViewControl: false,
      zoomControl: false,
    }

    this.map = new google.maps.Map(this.mapElement.nativeElement, mapOptions);

    // const from = {
    //   lat: parseFloat(this.order.address.store.lat),
    //   lng: parseFloat(this.order.address.store.long)
    // }

    // const to = {
    //   lat: parseFloat(this.order.address.customer.lat),
    //   lng: parseFloat(this.order.address.customer.long)
    // }

    // this.placeMarker(from, 'store')
    // this.placeMarker(to, 'delivery')
    // this.placeDirection(from, to);
  }

  async track(): Promise<any> {
    if(
      !this.trackCode || 
      this.trackCode.length !== "LOJAZ34314775BR7959".length || 
      !this.trackCode.includes("LOJAZ") ||
      !this.trackCode.includes("BR") 
    ){
      return this.toast.show("Código de rastreio inválido", "Código inválido")
    }

    this.loading = true;

    this.api.getWithoutToken(`/orders/search?trackCode=${this.trackCode}`)
      .subscribe({
        next: (data: any) => {
          
          if(!data.status){
            this.toast.show(data.message)
          }else{
            this.order = {
              ...data.data
            }
            this.placeDirection(
              {lat: Number(this.order.address.store.lat), lng: Number(this.order.address.store.long)},
              {lat: Number(this.order.address.customer.lat), lng: Number(this.order.address.customer.long)},
            )
            this.startTracking(this.trackCode);
            this.showForm = false;
          }
          this.loading = false;
        },
        error: (err) => {
          this.loading = false;
          return this.toast.show(err.message)
        }
      });
  }

  placeMarker(location: Location, type: string) {
    switch (type) {
      case 'store':
        this.markers.store.marker = new google.maps.Marker({
          position: location,
          map: this.map,
        });
        break;
      case 'delivery':
        this.markers.delivery.marker = new google.maps.Marker({
          position: location,
          map: this.map,
        });
        break;
      case 'customer':
        this.markers.customer.marker = new google.maps.Marker({
          position: location,
          map: this.map,
        });
        break;
    }

    this.map.setCenter(location);
  }

  placeDirection(from: Location, to: Location) {
    this.directionsRenderer.setMap(this.map);  
    
    const request = {
      origin: from,
      destination: to,
      travelMode: google.maps.TravelMode.DRIVING,      
    }

    this.directionsService.route(request, (result: any, status: any) => {
      if (status == 'OK') {
        this.directionsRenderer.setDirections(result);
      }
    }
    );
   
    this.removeMarkers();
  }

  removeMarkers(){
    if(this.markers.delivery.marker) (this.markers.delivery.marker as any).setMap(null);
    if(this.markers.store.marker) (this.markers.store.marker as any).setMap(null);
    if(this.markers.customer.marker) (this.markers.customer.marker as any).setMap(null);
  }

  needHelp(){
    window.open('https://api.whatsapp.com/send?phone=5521968368383&text=Ol%C3%A1%2C%20preciso%20de%20ajuda com o LojAZ Delivery!', '_blank');
  }
}