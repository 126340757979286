<div class="container">
    <div #map class="map" id="map"></div>
    <div class="content">

        <div class="back" (click)="handleSteps('BACK')">
            <img src="../../../../../assets/icons/arrow-back.svg" alt="">
        </div>

        <!-- origin -->
        <ng-container *ngIf="step===0">
            <form class="form" [formGroup]="storeAddress">
                <div class="header">
                    <h2>Endereço de origem</h2>
                    <h4>Defina o endereço onde o pedido será retirado.</h4>
                </div>

                <div class="input-container">
                    <input #search id="search" type="text" placeholder="Endereço" formControlName="street">
                </div>

                <!-- <div *ngIf="showFields.store" class="aux-container">
                        <div class="input-container">
                            <input type="text" placeholder="Número" formControlName="number">
                        </div>

                        <div class="input-container">
                            <input type="text" placeholder="CEP" formControlName="postalCode">
                        </div>
                    </div> -->

                <div *ngIf="showFields.store" class="input-container">
                    <input type="text" placeholder="Complemento" formControlName="complement">
                </div>


                <button *ngIf="(storeAddress.value.street === originalAddress.store.street) && !loading"
                    (click)="handleSteps('NEXT')" class="btn-next" [disabled]="!storeAddress.valid">Continuar</button>

                <button *ngIf="(storeAddress.value.street != originalAddress.store.street) && !loading"
                    (click)="handleSteps('CHECK')" class="btn-next" [disabled]="!storeAddress.valid">Atualizar
                    endereço</button>

                <button *ngIf="loading" class="btn-next" [disabled]="true">
                    <mat-spinner *ngIf="loading" diameter="20"></mat-spinner>
                </button>
            </form>
        </ng-container>


        <!-- destination -->
        <ng-container *ngIf="step===1">
            <form class="form" [formGroup]="customerAddress">
                <div class="header">
                    <h2>Endereço de destino</h2>
                    <h4>Defina o endereço onde o pedido será entregue.</h4>
                </div>

                <div class="input-container">
                    <input #search id="search" type="text" placeholder="Endereço" formControlName="street">
                </div>

                <!-- <div *ngIf="showFields.customer" class="aux-container">
                    <div class="input-container">
                        <input type="text" placeholder="Número" formControlName="number">
                    </div>

                    <div class="input-container">
                        <input type="text" placeholder="CEP" formControlName="postalCode">
                    </div>
                </div> -->

                <div *ngIf="showFields.customer" class="input-container">
                    <input type="text" placeholder="Complemento" formControlName="complement">
                </div>

                <button *ngIf="(customerAddress.value.street === originalAddress.customer.street) && !loading"
                    (click)="handleSteps('NEXT')" class="btn-next"
                    [disabled]="!customerAddress.valid">Continuar</button>

                <button *ngIf="(customerAddress.value.street != originalAddress.customer.street) && !loading"
                    (click)="handleSteps('CHECK')" class="btn-next" [disabled]="!customerAddress.valid">Atualizar
                    endereço</button>

                <button *ngIf="loading" class="btn-next" [disabled]="true">
                    <mat-spinner *ngIf="loading" diameter="20"></mat-spinner>
                </button>
            </form>
        </ng-container>

        <!-- destination -->
        <ng-container *ngIf="step===2">
            <form class="form">
                <div class="header">
                    <h2>Confirme os endereços</h2>
                    <h4>O entregador irá buscar em:</h4>
                </div>

                <div class="input-container">
                    <img (click)="handleSteps('REFRESH_AUTOCOMPLETE'); step = 0"
                        src="../../../../../assets/icons/edit.svg" width="20">
                    <input type="text" placeholder="Endereço" disabled [value]="storeAddress.value.street || ''">
                </div>

                <!-- <div class="aux-container">
                    <div class="input-container">
                        <input type="text" placeholder="Número" disabled [value]="storeAddress.value.number || ''">
                    </div>

                    <div class="input-container">
                        <input type="text" placeholder="CEP" disabled [value]="storeAddress.value.postalCode || ''">
                    </div>
                </div> -->

                <div class="input-container">
                    <input type="text" placeholder="Complemento" disabled [value]="storeAddress.value.complement || ''">
                </div>

                <div class="header">
                    <h4>O entregador irá entregar em:</h4>
                </div>

                <div class="input-container">
                    <img (click)="handleSteps('REFRESH_AUTOCOMPLETE'); step = 1"
                        src="../../../../../assets/icons/edit.svg" width="20">
                    <input type="text" placeholder="Endereço" disabled [value]="customerAddress.value.street || ''">
                </div>

                <!-- <div class="aux-container">
                    <div class="input-container">
                        <input type="text" placeholder="Número" disabled [value]="customerAddress.value.number || ''">
                    </div>

                    <div class="input-container">
                        <input type="text" placeholder="CEP" disabled [value]="customerAddress.value.postalCode || ''">
                    </div>
                </div> -->

                <div class="input-container">
                    <input type="text" placeholder="Complemento" disabled
                        [value]="customerAddress.value.complement || ''">
                </div>

                <button (click)="checkAvailability()" class="btn-next">
                    <p *ngIf="!loading">Verificar disponibilidade</p>
                    <mat-spinner *ngIf="loading" diameter="20"></mat-spinner>
                </button>
            </form>
        </ng-container>

        <ng-container *ngIf="step===3">
            <form class="form" [formGroup]="customer">
                <div class="header">
                    <h2>Quem irá receber?</h2>
                    <h4>Defina os dados do cliente.</h4>
                </div>

                <div class="input-container">
                    <input type="text" placeholder="Nome" formControlName="name" [value]="customer.value.name || ''">
                </div>

                <!-- <div class="input-container">
                    <input type="text" mask="000.000.000-00" placeholder="CPF" formControlName="document" [value]="customer.value.document || ''">
                </div>

                <div class="input-container">
                    <input type="email" placeholder="Email para contato" formControlName="email" [value]="customer.value.email || ''">
                </div> -->

                <div class="input-container">
                    <input mask="(00) 00000-0000" type="text" id="phoneNumber" class="form-control"
                        placeholder="Celular para contato" formControlName="phoneNumber">
                </div>

                <button (click)="handleSteps('NEXT')" class="btn-next" [disabled]="!customer.valid">Continuar</button>
            </form>
        </ng-container>

        <ng-container *ngIf="step===4">
                <form [formGroup]="itemForm" class="form" style="overflow: auto; max-height: 80%;">
                    <div class="header">
                        <h2>Termos de uso</h2>
                        <h4>Ao solicitar, você <span style="font-weight: 900;">concorda</span> que:</h4>
                    </div>

                    <div class="sub-header">
                        <h3>Instruções do item:</h3>
                        <h4>1. 10 kg ou menos.</h4>
                        <h4>2. Valor de até R$ 3000 para viagens com seguro do item.</h4>
                        <h4>3. Devidamente embalado e pronto para retirada.</h4>

                        <br>
                        <h3>Itens proibidos:</h3>
                        <h4>Bebidas alcoólicas, medicamentos, drogas, armas de fogo e produtos perigosos ou ilegais são
                            rigorosamente proibidos.</h4>

                        <br>
                        <h3>Observação:</h3>
                        <h4>
                            O envio de itens pelo LojAZ Delivery deve seguir as políticas da empresa e as leis e
                            regulamentos vigentes. Qualquer violação será reportada às autoridades, e o usuário poderá
                            ter o acesso ao sistema suspenso. O LojAZ Delivery irá cooperar com as autoridades em casos
                            de atividades ilegais.
                        </h4>
                        <br>
                    </div>


                    <button (click)="handleSteps('FINISH')" class="btn-next">
                        <p *ngIf="!loading">Solicitar entregador</p>
                        <mat-spinner *ngIf="loading" diameter="20"></mat-spinner>
                    </button>
                </form>
        </ng-container>
    </div>
</div>