import { Component } from '@angular/core';
import { RouterLink } from '@angular/router';
import { GenericTableComponent } from '../../../components/dashboard/generic-table/generic-table.component';
import { ApiService } from '../../../services/api.service';
import { UtilityService } from '../../../services/utility';

@Component({
  selector: 'app-orders',
  standalone: true,
  imports: [
    RouterLink,
    GenericTableComponent
  ],
  templateUrl: './orders.component.html',
  styleUrl: './orders.component.scss'
})
export class OrdersComponent {
  orders: any;
  loadingOrders: boolean = false;

  constructor(
    private api: ApiService,
    private utility: UtilityService,
  ) { 
    this.loadOrders();
  }

  async loadOrders(page: number = 1){
    this.loadingOrders = true;
    //{{BASE_URL}}/stores/:storeId/orders?token={{ACCESS_TOKEN}}&limit=10
    this.api.get(`/stores/${this.utility.getCurrentStore()._id}/orders?&limit=10&page=${page}`).subscribe({
      next: (response) => {
        this.orders = this.handleOrdersData(response);
        // this.orders['metadata'] = this.getMetadata();
        this.loadingOrders = false;
      },
      error: () => {
        this.loadingOrders = false;
      }
    });
  }

  handleOrdersData(response: any): any{
    //for each order add a new property customName, customCustomerStreet, customStoreStreet

    response.data.forEach((order: any) => {
      order.customName = order.client.name;
      
    });
    console.log(response);
  }

  getMetadata(): any{
    return {
      placeholder: 'Pesquisar',
      title: 'Listagem de movimentações',
      headers: [
        {
          title: 'Cliente',
          value: 'customer.name',
          type: 'text'
        },
        {
          title: 'Status',
          value: 'status',
          type: 'status'
        },
        {
          title: 'Pedido feito em:',
          value: 'createdAt',
          type: 'date'
        },
        {
          title: 'Entregue em:',
          value: 'lastMovimentationAt',
          type: 'date'
        }      
      ]
    }
  }
}
