@if(showMenu){
    <div class="side-menu" [class.closed]="!showMenu" [class.closeAnimation]="closeAnimation">
        <a href="/track">
            <img src="../../../../assets/icons/logo.svg" alt="logo" class="logo">
        </a>

        <hr>

        <div class="navigation">
            @if(authService.getUserType() === 'user'){
            <div (click)="handleMenuAnimation();" class="nav-item" [routerLink]="['/track']" [routerLinkActive]="'active'">Início</div>
            <div (click)="handleMenuAnimation();" class="nav-item" [routerLink]="['/payments']" [routerLinkActive]="'active'">Pagamentos</div>
            }

            @if(authService.getUserType() === 'admin'){
            <div (click)="handleMenuAnimation();" class="nav-item" [routerLink]="['/delivery-pendings']" [routerLinkActive]="'active'">Entregadores</div>
            }

        </div>

        <div class="default-navigation">
            @if(authService.getUserType() === 'user'){
                <div (click)="handleMenuAnimation();" class="nav-item" [routerLink]="['/store/select']" [routerLinkActive]="'active'">Minhas lojas</div>
            }
            <div (click)="handleMenuAnimation();" class="nav-item" (click)="logout()">Sair</div>
        </div>
    </div>
    <div class="overlay" [class.closed]="!showMenu" [class.closeAnimation]="closeAnimation" (click)="handleMenuAnimation();"></div>
}

<div class="container">
    <div class="logo-container">
        <a href="/track">
            <img src="../../../../assets/icons/logo.svg" alt="logo" class="logo">
        </a>

        <!-- burger -->
        <div class="burger" (click)="showMenu = !showMenu;">
            <img src="../../../../assets/icons/menu.svg" alt="">
        </div>
    </div>

    <div class="navigation">
        @if(authService.getUserType() === 'user'){
            <div class="nav-item" [routerLink]="['/track']" [routerLinkActive]="'active'">Início</div>
            <div class="nav-item" [routerLink]="['/payments']" [routerLinkActive]="'active'">Pagamentos</div>
        }

        @if(authService.getUserType() === 'admin'){
            <div class="nav-item" [routerLink]="['/delivery-pendings']" [routerLinkActive]="'active'">Entregadores</div>
        }
    </div>

    <div class="side-options">
        @if(authService.getUserType() === 'user'){
            <div [ngSwitch]="showWallet">
                <div *ngSwitchCase="true" class="wallet-container">
                    R${{(wallet.balance || 0)}}
                    <img (click)="showWallet = !showWallet" src="../../../../assets/icons/eye.svg" alt="eye" class="eye">
                </div>
                <div *ngSwitchCase="false" class="wallet-container">
                    R$ ****
                    <img (click)="showWallet = !showWallet" src="../../../../assets/icons/eyeoff.svg" alt="eye" class="eye">
                </div>
            </div>

            <div class="notification-button" #notificationButton (click)="showNotifications = !showNotifications">
                <div class="badge" *ngIf="notificationCount > 0">{{notificationCount}}</div>
                <img src="../../../../assets/icons/notification.svg" alt="notification" class="notification">
                <ng-container *ngIf="showNotifications">
                    <div class="notification-container-triangle"></div>
                    <div class="notification-container-triangle-mask"></div>
                    <div class="notifications-container">
                        <div *ngIf="notifications.length === 0" class="no-notifications">Nenhuma notificação</div>
                        <app-notification-card *ngFor="let notification of notifications"
                            [notification]="notification"></app-notification-card>
                    </div>
                </ng-container>
            </div>
         }


        <div mat-button [matMenuTriggerFor]="userMenu" class="profile-container">
            {{authService.getFirstLetter() | uppercase}}
            <mat-menu #userMenu="matMenu">
                @if(authService.getUserType() === 'user'){
                <button mat-menu-item [routerLink]="['/store/select']">Minhas lojas</button>
                }
                <button mat-menu-item (click)="logout()">Sair</button>
            </mat-menu>
        </div>
    </div>
</div>