import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Socket } from './socket.service';
import { UtilityService } from './utility';

@Injectable({
  providedIn: 'root',
})
export class AuthGuardService {
  private socketInitialized = false;

  constructor(
    private jwtHelper: JwtHelperService,
    private router: Router,
    private utilityService: UtilityService
  ) {}

  public initializeSocket(token: string) {
    this.socketInitialized = true;
    Socket.emit('authIn', { token });

    Socket.on('teste', (data) => {
      console.log(data);
    });
  }

  public isAuthenticated(): boolean {
    const token = localStorage.getItem('token');

    if (token && !this.socketInitialized) {
      this.initializeSocket(token);
    }

    if (token) {
      return !this.jwtHelper.isTokenExpired(token);
    }
    return false;
  }

  public hasStore(): boolean {
    const dataString = localStorage.getItem('data');
    const data = JSON.parse(dataString ? dataString : '{}');

    if (data.hasStore) {
      return true;
    }
    return false;
  }

  public hasPlan(): boolean {
    const dataString = localStorage.getItem('currentStore');
    const data = JSON.parse(dataString ? dataString : '{}');

    if (data.planId) {
      return true;
    }
    return false;
  }

  canActivate(): boolean {
    const userDataString = localStorage.getItem('data');
    const userData = userDataString ? JSON.parse(userDataString) : null;

    if (!userData) {
      this.router.navigate(['/auth/signin']);
      return false;
    }

    if (!this.isAuthenticated()) {
      this.router.navigate(['/auth/signin']);
      return false;
    }

    if (userData.type === 'admin') {
      return true;
    }

    if (!this.hasStore()) {
      this.router.navigate(['/store/new']);
      return false;
    }

    if (this.hasStore() && !this.hasPlan()) {
      this.router.navigate(['/store/plan']);
      return false;
    }

    Socket.emit('setCurrentStore', {
      storeId: this.utilityService.getCurrentStore()._id,
    });

    return true;
  }
}
