import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(
    private api: ApiService,
    private router: Router,
    private toastr: ToastrService,
    public jwtHelper: JwtHelperService
  ) {}

  public isAuthenticated(): boolean {
    const token = localStorage.getItem('token');

    if (token) {
      try {
        return !this.jwtHelper.isTokenExpired(token);
      } catch {
        return false;
      }
    }
    return false;
  }

  public login(
    email: string,
    password: string,
    type: string = 'user'
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.api.post('/users/session', { email, password, type }).subscribe({
        next: (response: any) => {
          if (response.status) {
            localStorage.setItem('token', response.data.token);
            localStorage.setItem('data', JSON.stringify(response.data));

            if (type === 'admin') {
              this.router.navigate(['/delivery-pendings']);
            } else{
              if (response.data.hasStore === true) {
                this.router.navigate(['/store/select']);
              } else {
                this.router.navigate(['/store/new']);
              }
            }
            resolve();
          }
        },
        error: (error) => {
          this.toastr.show(error.error.message, 'Erro');
          reject(error);
        },
      });
    });
  }

  public logout() {
    localStorage.removeItem('data');
    localStorage.removeItem('stores');
    localStorage.removeItem('token');
    localStorage.removeItem('currentStore');
  }

  public getFirstLetter() {
    const data = JSON.parse(localStorage.getItem('data') || '{}');
    let firstLetter = data.name.charAt(0).toUpperCase();
    return firstLetter;
  }

  public getUserType() {
    const data = JSON.parse(localStorage.getItem('data') || '{}');
    return data.type;
  }
}
