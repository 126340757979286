import { Component, Input } from '@angular/core';
import { Menu } from '../../types/models';
import { CommonModule } from '@angular/common';
import { ActivatedRoute, Router, RouterLink, RouterLinkActive, RouterOutlet } from '@angular/router';

@Component({
  selector: 'app-dynamic-menu',
  standalone: true,
  imports: [
    CommonModule,
    RouterOutlet,
    RouterLink,
    RouterLinkActive
  ],
  templateUrl: './dynamic-menu.component.html',
  styleUrl: './dynamic-menu.component.scss'
})
export class DynamicMenuComponent {
  @Input() menus: Menu[] = [];

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute
  ){}

  ngOnInit() {
    
  }
}
