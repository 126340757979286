import { CommonModule } from '@angular/common';
import { AfterContentInit, Component, ElementRef, NgZone, ViewChild } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ApiService } from '../../../../services/api.service';
import { ToastrService } from 'ngx-toastr';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { UtilityService } from '../../../../services/utility';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';

declare const google: any;

@Component({
  selector: 'app-new-order',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    MatProgressSpinnerModule,
    NgxMaskDirective,
    NgxMaskPipe
  ],
  providers: [
    provideNgxMask()
  ],
  templateUrl: './new-order.component.html',
  styleUrl: './new-order.component.scss'
})
export class NewOrderComponent implements AfterContentInit {
  step = 0;
  loading: boolean = false;

  originalAddress = {
    store: {
      street: '',
      mustCheck: false
    },
    customer: {
      street: '',
      mustCheck: false
    }
  }

  markers = {
    origin: {
      latitude: 0,
      longitude: 0,
      marker: null
    },
    destination: {
      latitude: 0,
      longitude: 0,
      marker: null
    },
    directions: null
  }

  showFields = {
    customer: false,
    store: false
  }

  customerAddress = new FormGroup({
    street: new FormControl('', Validators.required),
    number: new FormControl('', Validators.required),
    complement: new FormControl(''),
    // neighborhood: new FormControl(''),
    city: new FormControl('', Validators.required),
    state: new FormControl('', Validators.required),
    postalCode: new FormControl('', Validators.required),
    lat: new FormControl('', Validators.required),
    long: new FormControl('', Validators.required),
  })

  storeAddress = new FormGroup({
    street: new FormControl('', Validators.required),
    number: new FormControl('', Validators.required),
    complement: new FormControl(''),
    // neighborhood: new FormControl(''),
    city: new FormControl('', Validators.required),
    state: new FormControl('', Validators.required),
    postalCode: new FormControl('', Validators.required),
    lat: new FormControl('', Validators.required),
    long: new FormControl('', Validators.required),
  })

  customer = new FormGroup({
    name: new FormControl('', Validators.required),
    phoneNumber: new FormControl('', Validators.required),
  })

  itemForm = new FormGroup({
    name: new FormControl('', [Validators.minLength(3), Validators.required]),
    price: new FormControl('', Validators.required),
    weight: new FormControl('', Validators.required),
    // quantity: new FormControl('', Validators.required)
  })

  map: any;
  @ViewChild('map', { static: true }) mapElement!: ElementRef;
  @ViewChild('search', { static: false }) searchElement!: ElementRef;

  constructor(
    private router: Router,
    private api: ApiService,
    private toastr: ToastrService,
    private utility: UtilityService,
    private zone: NgZone
  ) {
  }

  ngAfterContentInit() {
    this.startMap();
    this.checkAndStartAutocomplete();
  }

  checkCurrentStoreAddress() {
    let currentStore = this.utility.getCurrentStore();

    if (currentStore && currentStore.address) {
      this.storeAddress.patchValue({
        street: currentStore.address.address,
        lat: currentStore.address.lat,
        long: currentStore.address.long
      });

      this.markers.origin.latitude = parseFloat(currentStore.address.latitude);
      this.markers.origin.longitude = parseFloat(currentStore.address.longitude);
      this.handleMarkers('origin');

      this.originalAddress.store.street = currentStore.address.address;
      this.updateAddressByName();
     
    }
  }

  startMap() {
    const initialPosition = {
      lat: -22.9819427,
      lng: -43.2203723,
    }

    const mapOptions = {
      center: initialPosition,
      zoom: 14,
      mapTypeControl: false,
      fullscreenControl: false,
      clickableIcons: false,
      streetViewControl: false,
    }

    this.map = new google.maps.Map(this.mapElement.nativeElement, mapOptions);

    this.map.addListener('click', (event: any) => {
      this.handleMapClick(event);
    });

    this.checkCurrentStoreAddress();
  }

  checkAndStartAutocomplete() {
    setTimeout(() => {
      if (this.searchElement && this.searchElement.nativeElement) {
        this.startAutocomplete();
      } else {
        this.checkAndStartAutocomplete();
      }
    }, 500);
  }

  startAutocomplete() {
    const autocomplete = new google.maps.places.Autocomplete((this.searchElement.nativeElement as HTMLInputElement), {
      types: ['geocode'],
      componentRestrictions: { country: 'BR' },
    });

    autocomplete.addListener('place_changed', () => {
      const place = autocomplete.getPlace();

      let address: any = {
        address: '',
        number: '',
        city: '',
        uf: '',
        latitude: '',
        longitude: '',
        complement: '',
        // neighborhood: '',
        postalCode: ''
      };

      address.address = place.formatted_address;
      address.number = place.address_components?.find((component: { types: string[]; }) => component.types.includes('street_number'))?.long_name;
      address.city = place.address_components?.find((component: { types: string[]; }) => component.types.includes('administrative_area_level_2'))?.long_name;
      address.uf = place.address_components?.find((component: { types: string[]; }) => component.types.includes('administrative_area_level_1'))?.short_name;
      address.latitude = place.geometry.location.lat();
      address.longitude = place.geometry.location.lng();
      address.postalCode = place.address_components?.find((component: { types: string[]; }) => component.types.includes('postal_code'))?.long_name;

      if (this.step === 0) {
        this.handlePlaceChanged('store', address);
      }
      else {
        this.handlePlaceChanged('customer', address);
      }
    });
  }

  handleSteps(action: string) {
    if (action === 'BACK') {
      this.step--;
    }

    if (action === 'NEXT') {
      this.step++;
    }

    if (action === 'CHECK') {
      this.updateAddressByName();
    }

    if (action === 'FINISH') {
      this.handleFinish();
    }

    if (this.step < 0) {
      this.router.navigate(['/track']);
    }

    if (this.step === 0 || this.step === 1 || action === 'REFRESH_AUTOCOMPLETE') {
      setTimeout(() => {
        this.startAutocomplete();
      }, 500);
    }
  }

  handleFinish() {
    this.loading = true;

    let order: any = {
      address: {
        customer: this.customerAddress.value,
        store: this.storeAddress.value
      },
      client: this.customer.value
    }

    console.log(order);
    let currentStoreId = this.utility.getCurrentStore()._id;

    this.api.post('/orders/' + currentStoreId, order).subscribe({
      next: (response: any) => {
        if (response.status === true) {
          this.toastr.show('Pedido criado com sucesso!');
          this.router.navigate(['/track']);
          this.loading = false;
        }
        else {
          this.toastr.show(response.message);
          this.loading = false;
        }
      },
      error: () => {
        this.toastr.show('Ocorreu um erro ao criar o pedido. Tente novamente mais tarde.');
        this.loading = false;
      }
    })
  }

  handlePlaceChanged(formGroup: string, address: any) {
    if (!address.number) {
      this.toastr.show('Você também deve incluir o número do endereço.');
      return;
    }

    if (formGroup === 'store') {
      this.storeAddress.patchValue({
        street: address.address,
        number: address.number,
        complement: address.complement,
        // neighborhood: address.neighborhood,
        city: address.city,
        state: address.uf,
        postalCode: address.postalCode,
        lat: address.latitude,
        long: address.longitude
      });

      this.markers.origin.latitude = address.latitude;
      this.markers.origin.longitude = address.longitude;
      console.log(this.markers.origin);
      this.handleMarkers('origin');
      this.showFields.store = true;
      this.originalAddress.store.mustCheck = false;
      this.originalAddress.store.street = address.address;

      this.zone.run(() => {
        this.storeAddress.updateValueAndValidity();
      })
    }
    else {
      this.customerAddress.patchValue({
        street: address.address,
        number: address.number,
        complement: address.complement,
        // neighborhood: address.neighborhood,
        city: address.city,
        state: address.uf,
        postalCode: address.postalCode,
        lat: address.latitude,
        long: address.longitude
      });

      this.markers.destination.latitude = address.latitude;
      this.markers.destination.longitude = address.longitude
      this.handleMarkers('destination');
      this.showFields.customer = true;
      this.originalAddress.customer.mustCheck = false;
      this.originalAddress.customer.street = address.address;

      this.zone.run(() => {
        this.customerAddress.updateValueAndValidity();
      });
    }
  }

  handleMarkers(type: string) {
    if (type === 'origin') {
      const latitude = this.markers.origin.latitude;
      const longitude = this.markers.origin.longitude;

      if (this.markers.origin.marker) {
        (this.markers.origin.marker as any).setMap(null);
      }

      this.markers.origin.marker = new google.maps.Marker({
        position: { lat: latitude, lng: longitude },
        map: this.map,
        title: 'Origin'
      });

      this.map.setCenter({ lat: latitude, lng: longitude });
    }
    else {
      const latitude = this.markers.destination.latitude;
      const longitude = this.markers.destination.longitude;

      if (this.markers.destination.marker) {
        (this.markers.destination.marker as any).setMap(null);
      }

      this.markers.destination.marker = new google.maps.Marker({
        position: { lat: latitude, lng: longitude },
        map: this.map,
        title: 'Destination'
      });

      this.map.setCenter({ lat: latitude, lng: longitude });
    }

    if (this.markers.origin.marker && this.markers.destination.marker) {
      this.drawRoute();
    }
  }

  directionsService = new google.maps.DirectionsService();
  directionsRenderer = new google.maps.DirectionsRenderer({
    polylineOptions: {
      strokeColor: 'black'
    }
  });

  drawRoute() {
    this.directionsRenderer.setMap(this.map);

    const origin = new google.maps.LatLng(this.markers.origin.latitude, this.markers.origin.longitude);
    const destination = new google.maps.LatLng(this.markers.destination.latitude, this.markers.destination.longitude);

    const request = {
      origin: origin,
      destination: destination,
      travelMode: google.maps.TravelMode.DRIVING
    };

    this.directionsService.route(request, (result: any, status: any) => {
      if (status == 'OK') {
        this.directionsRenderer.setDirections(result);
      }
    });

    this.removeMarkers();
  }

  removeMarkers() {
    if (this.markers.origin.marker) (this.markers.origin.marker as any).setMap(null);
    if (this.markers.destination.marker) (this.markers.destination.marker as any).setMap(null);
  }

  handleMapClick(event: any) {
    if (this.step === 0) {
      this.markers.origin.latitude = event.latLng.lat();
      this.markers.origin.longitude = event.latLng.lng();
      this.handleMarkers('origin');
      this.updateAddress('store', event.latLng.lat(), event.latLng.lng());
    }
    else {
      this.markers.destination.latitude = event.latLng.lat();
      this.markers.destination.longitude = event.latLng.lng();
      this.handleMarkers('destination');
      this.updateAddress('customer', event.latLng.lat(), event.latLng.lng());
    }
  }

  updateAddressByName() {
    this.loading = true;
    if (this.step === 0) {
      const geocoder = new google.maps.Geocoder();
      const address = this.storeAddress.value.street + ', ' + this.storeAddress.value.number;
      geocoder.geocode({ address: address }, (results: any, status: any) => {
        if (status === 'OK') {
          if (results[0]) {
            const addressComponents = results[0].address_components;
            const number = addressComponents.find((component: { types: string[]; }) => component.types.includes('street_number'))?.long_name;
            if (!number) {
              this.loading = false;
              this.toastr.show('Você também deve incluir o número do endereço.');
              return;
            }

            const location = results[0].geometry.location;
            this.storeAddress.patchValue({
              street: results[0].formatted_address,
              number: number,
              lat: location.lat(),
              long: location.lng(),
              city: addressComponents.find((component: { types: string[]; }) => component.types.includes('administrative_area_level_2'))?.long_name,
              state: addressComponents.find((component: { types: string[]; }) => component.types.includes('administrative_area_level_1'))?.short_name,
              postalCode: addressComponents.find((component: { types: string[]; }) => component.types.includes('postal_code'))?.long_name
            });
            this.originalAddress.store.street = results[0].formatted_address;

            this.zone.run(() => {
              this.storeAddress.updateValueAndValidity();
            })
            this.markers.origin.latitude = location.lat();
            this.markers.origin.longitude = location.lng();
            this.handleMarkers('origin');
            this.loading = false;
          }          
        }
        else{
          this.loading = false;
          this.toastr.show('Endereço não encontrado.');
        }
      });
    }
    else {
      const geocoder = new google.maps.Geocoder();
      const address = this.customerAddress.value.street + ', ' + this.customerAddress.value.number;
      geocoder.geocode({ address: address }, (results: any, status: any) => {
        if (status === 'OK') {
          if (results[0]) {
            const addressComponents = results[0].address_components;
            const number = addressComponents.find((component: { types: string[]; }) => component.types.includes('street_number'))?.long_name;
            if (!number) {
              this.loading = false;
              this.toastr.show('Você também deve incluir o número do endereço.');
              return;
            }

            const location = results[0].geometry.location;
            this.customerAddress.patchValue({
              street: results[0].formatted_address,
              number: number,
              lat: location.lat(),
              long: location.lng(),
              city: addressComponents.find((component: { types: string[]; }) => component.types.includes('administrative_area_level_2'))?.long_name,
              state: addressComponents.find((component: { types: string[]; }) => component.types.includes('administrative_area_level_1'))?.short_name,
              postalCode: addressComponents.find((component: { types: string[]; }) => component.types.includes('postal_code'))?.long_name
            });
            this.originalAddress.customer.street = results[0].formatted_address;

            this.zone.run(() => {
              this.customerAddress.updateValueAndValidity();
            });
            this.markers.destination.latitude = location.lat();
            this.markers.destination.longitude = location.lng();
            this.handleMarkers('destination');
            this.loading = false;
          }
        }
      });
    }
  }

  updateAddress(formGroup: string, latitude: number, longitude: number) {
    const geocoder = new google.maps.Geocoder();
    const latLng = new google.maps.LatLng(latitude, longitude);

    geocoder.geocode({ location: latLng }, (results: any, status: any) => {
      if (status === 'OK') {
        if (results[0]) {
          const addressComponents = results[0].address_components;
          const address: any = {
            address: '',
            number: '',
            city: '',
            uf: '',
            latitude: '',
            longitude: '',
            complement: '',
            postalCode: ''
          };

          address.address = results[0].formatted_address;
          address.number = addressComponents.find((component: { types: string[]; }) => component.types.includes('street_number'))?.long_name;
          address.city = addressComponents.find((component: { types: string[]; }) => component.types.includes('administrative_area_level_2'))?.long_name;
          address.uf = addressComponents.find((component: { types: string[]; }) => component.types.includes('administrative_area_level_1'))?.short_name;
          address.latitude = latitude;
          address.longitude = longitude;
          address.postalCode = addressComponents.find((component: { types: string[]; }) => component.types.includes('postal_code'))?.long_name;

          if (formGroup === 'store') {
            this.storeAddress.patchValue({
              street: address.address,
              number: address.number,
              complement: address.complement,
              city: address.city,
              state: address.uf,
              postalCode: address.postalCode,
              lat: address.latitude,
              long: address.longitude
            });

            this.zone.run(() => {
              this.storeAddress.updateValueAndValidity();
            });
            this.originalAddress.store.street = address.address;

            if (address.number) {
              this.showFields.store = true;
            }
          }
          else {
            this.customerAddress.patchValue({
              street: address.address,
              number: address.number,
              complement: address.complement,
              city: address.city,
              state: address.uf,
              postalCode: address.postalCode,
              lat: address.latitude,
              long: address.longitude
            });

            this.zone.run(() => {
              this.customerAddress.updateValueAndValidity();
            });
            this.originalAddress.customer.street = address.address;

            if (address.number) {
              this.showFields.customer = true;
            }
          }

          if (!address.number) {
            this.toastr.show('Você também deve incluir o número do endereço.');
            return;
          }
        }
      }
    });
  }

  checkAvailability() {
    this.loading = true;

    const startLat = this.storeAddress.value.lat;
    const startLong = this.storeAddress.value.long;
    const endLat = this.customerAddress.value.lat;
    const endLong = this.customerAddress.value.long;

    const url = `/tools/location/isValid?startLat=${startLat}&startLong=${startLong}&endLat=${endLat}&endLong=${endLong}`;

    this.api.getWithoutToken(url).subscribe({
      next: (response: any) => {
        if (response.status === true) {
          this.step++;
          this.loading = false;
        }
        else {
          this.toastr.show(response.message);
          this.loading = false;
        }
      },
      error: (error: any) => {
        this.toastr.show('Ocorreu um erro ao verificar a disponibilidade. Tente novamente mais tarde.');
        this.loading = false;
      }
    });
  }
}
