import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'inOut',
  standalone: true
})
export class InOutPipe implements PipeTransform {
  transform(value: string): string {
    if(value.toUpperCase() === 'IN') {
      return 'Entrada';
    } else if(value.toUpperCase() === 'OUT') {
      return 'Saída';
    }

    return '';
  }
}
