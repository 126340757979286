import { Component, Input } from '@angular/core';
import { OrderTrack } from '../../../../types/order';
import { CommonModule } from '@angular/common';
import { OrderStatus } from '../../../../views/dashboard/track/track.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-order-card',
  standalone: true,
  imports: [
    CommonModule
  ],
  templateUrl: './order-card.component.html',
  styleUrl: './order-card.component.scss'
})
export class OrderCardComponent {
  constructor(
    private router: Router
  ){

  }

  @Input() order!: OrderTrack;
  statusLegends = {
    [OrderStatus.APPROVED]: "APROVADO",
    [OrderStatus.CANCELED]: "CANCELADO",
    [OrderStatus.PENDING]: "PENDENTE",
    [OrderStatus.CANCELED_BY_DELIVERY]: "CANCELADO PELO MOTOBOY",
    [OrderStatus.CANCELED_BY_STORE]: "CANCELADO PELA LOJA",
    [OrderStatus.COMPLETED]: "FINALIZADO",
    [OrderStatus.CREATED]: "CRIADO",
    [OrderStatus.DELIVERY_PICKEDUP]: "A CAMINHO",
    [OrderStatus.WAITING_DELIVERY_PICKUP]: "AGUARDANDO RETIRADA",
    [OrderStatus.SEARCHING_FOR_DELIVERY]: "BUSCANDO MOTOBOY",
    [OrderStatus.WAITING_CODE]: "MOTOBOY NO LOCAL",
  }

  get statusLegend() {
    return this.statusLegends[this.order.status as keyof typeof this.statusLegends];
  }

  openTracker(code: string) {
    const url = `/tracker/${code}`;
    window.open(url, '_blank');
  }
}
