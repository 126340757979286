import { Component } from '@angular/core';
import { WeeklySummary } from '../../../types/models';
import { WeeklySummaryComponent } from '../../../components/dashboard/summaries/weekly-summary/weekly-summary.component';

@Component({
  selector: 'app-summaries',
  standalone: true,
  imports: [
    WeeklySummaryComponent
  ],
  templateUrl: './summaries.component.html',
  styleUrl: './summaries.component.scss'
})
export class SummariesComponent {

  data: any = {
    "weekly_summary": [
      {
      "date": "26-06-24",
      "count": "5"
      },
      {
      "date": "25-06-24",
      "count": "2"
      },
      {
      "date": "24-06-24",
      "count": "1"
      },
      {
      "date": "23-06-24",
      "count": "9"
      },
      {
      "date": "22-06-24",
      "count": "7"
      },
      {
      "date": "21-06-24",
      "count": "4"
      },
      {
      "date": "20-06-24",
      "count": "4"
      },
    ]
  }


  

}
