import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { AsideBannerComponent } from '../../components/auth/aside-banner/aside-banner.component';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-auth',
  standalone: true,
  imports: [
    RouterOutlet,
    AsideBannerComponent,
    CommonModule
  ],
  templateUrl: './auth.component.html',
  styleUrl: './auth.component.scss'
})
export class AuthComponent {

}
