import { Component } from '@angular/core';
import { Menu } from '../../../types/models';
import { DynamicMenuComponent } from '../../../layouts/dynamic-menu/dynamic-menu.component';

@Component({
  selector: 'app-manage',
  standalone: true,
  imports: [
    DynamicMenuComponent
    ],
    templateUrl: './manage.component.html',
    styleUrl: './manage.component.scss'
})
export class ManageComponent {
  menus: Menu[] = [
    {
      title: 'Minha loja',
      icon: '../../../../../assets/icons/store.svg',
      route: '/manage/store'
    },
    {
      title: 'Usuários',
      icon: '../../../../../assets/icons/users.svg',
      route: '/manage/users'
    }
  ]
}
