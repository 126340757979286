<div class="auth-container">
    <div class="auth-content">
        <div class="auth-logo-container">
            <img src="../../../../assets/icons/logo.svg" alt="">
        </div>

        <div class="auth-form-container">
            <h1>Selecione a loja</h1>

            <div class="auth-form">
                <div class="auth-form-group" style="gap: 8px;">
                    <div (click)="setStore(store)" *ngFor="let store of stores" class="store-card">
                        <div class="store-card-body">
                            <p>{{store.name}}</p>
                            <span>{{store.address.address}}</span>
                        </div>
                    </div>
                </div>  

                <div class="auth-form-group" style="margin-top: 12px;">
                    <button class="auth-btn" [routerLink]="['/store/new']">
                        <p>Criar nova loja</p>
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>