<div class="sub-route-container">
    <div class="sub-route-title">
        LojAZ Delivery - Introdução
    </div>

    <div class="content">
        <div class="header">
            <div class="item-container">
                Url base
                <div class="box">
                    https://api.lojazdelivery.com.br
                    <img (click)="copy()" src="../../../../../assets/icons/copy.svg" alt="copy" width="15">
                </div>
            </div>
            <div class="item-container">
                Versão
                <div class="box">
                    1.0.3
                </div>
            </div>
        </div>

        <div class="info">
            Aqui, você aprenderá como integrar o LojAZ Delivery na sua plataforma de vendas online. Esta documentação oferece um fluxo completo para proporcionar a melhor experiência ao desenvolvedor.
            <br>Antes de continuar, esteja ciente de que toda plataforma necessita de cuidados especiais. Se for sua primeira vez aqui, utilize o ambiente de desenvolvimento para realizar testes e garantir que a integração esteja conforme o esperado.
        </div>
    </div>
</div>