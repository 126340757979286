import { CommonModule } from '@angular/common';
import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, RouterLink, RouterLinkActive } from '@angular/router';
import { NotificationCardComponent } from '../notification-card/notification-card.component';
import { Notification } from '../../../types/models';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { AuthService } from '../../../services/auth.service';
import { ApiService } from '../../../services/api.service';
import { BrlPipe } from '../../../pipes/brl.pipe';
import { WalletService } from '../../../services/wallet.service';

@Component({
  selector: 'app-navbar',
  standalone: true,
  imports: [
    CommonModule,
    RouterLink,
    RouterLinkActive,
    NotificationCardComponent,
    MatMenuModule,
    MatButtonModule,
    BrlPipe
  ],
  templateUrl: './navbar.component.html',
  styleUrl: './navbar.component.scss'
})
export class NavbarComponent implements OnInit{
  @ViewChild('notificationButton') notificationButton!: ElementRef;
  showWallet: boolean = true;
  showNotifications: boolean = false;
  showMenu: boolean = false;
  closeAnimation: boolean = false;
  notificationCount = 0;
  profileLetter = '';
  notifications: Notification[] = [];

  wallet = {
    balance: '0,00',
    pendingBalance: '0,00'
  }

  constructor(
    public authService: AuthService,
    private api: ApiService,
    private walletService: WalletService,
    private activatedRoute: ActivatedRoute
  ){
    if(this.authService.getUserType() === 'user'){
      this.walletService.getWallet().subscribe({
        next: (wallet) => {
          this.wallet = wallet;
        }
      });
      
      this.activatedRoute.queryParams.subscribe({
        next: () => {
          this.walletService.syncWallet();
        }
      });
    }
  }

  ngOnInit(){
    if(this.authService.getUserType() === 'user'){
      this.getNotificationCount();
      this.getNotifications();
    }
  }
  
  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent) {
    if (this.showNotifications && !this.notificationButton.nativeElement.contains(event.target)) {
      this.showNotifications = false;
    }
  }

  getNotificationCount(){
    this.api.getWithBearer('/notifications/count').subscribe({
      next: (response: any) => {
        this.notificationCount = response.data || 0;
      },
      error: (error) => {
        this.notificationCount = 0;
      }
    })
  }

  getNotifications(){
    this.api.getWithBearer('/notifications?page=1&limit=10').subscribe({
      next: (response: any) => {
        this.notifications = response.data.data.map((notification: any) => {
          return {
            id: notification._id,
            title: notification.title,
            description: notification.message,
            isRead: notification.isRead,
            origin: 'Sistema',
            priority: notification.priority
          }
        })
      }
    })
  }

  handleMenuAnimation(){
    this.closeAnimation = true;

    //wait 1.5 and animation started = false;
    setTimeout(() => {
      this.showMenu = false;
      this.closeAnimation = false;
    }, 500);
  }

  logout(){
    this.authService.logout();
    window.location.href = '/auth/signin';
  }
}
