import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { io, Socket as SocketBase } from 'socket.io-client';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SocketService {
    public message$: BehaviorSubject<string> = new BehaviorSubject<string>('');
    private isConnected = false;
    public socket: SocketBase;

    constructor(){
        this.socket = io(environment.baseApiUrl, {
            forceNew: true,
            autoConnect: true,
            reconnection: true,
            reconnectionDelay: 500,
        });
        this.loadListeners();
    }

    loadListeners(){
        this.socket.on("connect", () => {
            this.isConnected = true;
        })

        this.socket.on("ping", (dados) => {
            console.log(dados, "PONG DO BACKEND")
        })
    }

    on(evt: string, cb: (data: any) => void){
        this.socket.on(evt, cb);
    }

    emit(evt: string, data: any){
        this.socket.emit(evt, data);
    }


    sendMessage(message: string){
        this.socket.emit('message', message);
    }

    getMessage(){
        this.socket.on('message', (data: string) => {
            console.log(data);
            this.message$.next(data);
        });

        return this.message$.asObservable();
    }

    disconnect(){
        this.socket.disconnect();
    }
}

export const Socket = new SocketService().socket;
