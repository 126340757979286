<div [class]="order.selected? 'container selected' : 'container'">
    <div class="content">
        <div class="header">
            <div class="track-code" (click)="openTracker(order.trackCode)">
                #{{order.trackCode}}
            </div>

            <div class="status">
                {{statusLegend}}
            </div>
        </div>

        <div class="body">
            <div [class]="order.selected? 'address-container' : 'address-container'">
                <div class="label">
                    <img src="../../../../../assets/icons/map-pin.svg" alt="" width="15">
                    Origem:
                </div>
                <div class="address">
                    {{order.from.street}}
                </div>
            </div>

            <div [class]="order.selected? 'address-container' : 'address-container'">
                <div class="label">
                    <img src="../../../../../assets/icons/navigation.svg" alt="" width="15">
                    Destino:
                </div>
                <div class="address">
                    {{order.to.street}}
                </div>
            </div>
        </div>

        <hr>

        <div class="footer">
            <div class="label">
                Loja: <p>{{order.store.name}}</p>
            </div>

            <div *ngIf="order.delivery.name != ''" class="label">
                Entregador: <p>{{order.delivery.name}}</p>
            </div>
        </div>
    </div>
</div>