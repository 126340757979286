import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../../../services/api.service';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { StoreSelect } from '../../../../types/models';
import { CommonModule } from '@angular/common';
import { UtilityService } from '../../../../services/utility';
import { AuthService } from '../../../../services/auth.service';

@Component({
  selector: 'app-select-store',
  standalone: true,
  imports: [
    CommonModule,
    RouterLink
  ],
  templateUrl: './select-store.component.html',
  styleUrl: './select-store.component.scss'
})
export class SelectStoreComponent implements OnInit{
  stores: StoreSelect[] = [];

  constructor(
    private api: ApiService,
    private toastr: ToastrService,
    private router: Router,
    private utility: UtilityService,
    private activatedRoute: ActivatedRoute,
    private auth: AuthService
  ){
    this.activatedRoute.params.subscribe({
      next: () => {
        if(!this.auth.isAuthenticated()){
          this.router.navigate(['auth/signin']);
        }
      },
      error: () => {
        this.router.navigate(['']);
      }
    })
  }

  ngOnInit(): void {
    this.loadStores();
  }

  loadStores(){
    this.api.get('/stores').subscribe({
      next: (response: any) => {
        if(response.status){
          this.stores = response.data;
          // console.log(this.stores);
          this.utility.setStores(this.stores);
        }
      },
      error: (error) => {
        this.toastr.show('Erro ao carregar lojas. Tente novamente mais tarde.');
        // this.router.navigate(['/s']);
      }
    })
  }

  setStore(store: StoreSelect){
    this.utility.setCurrentStore(store);
    this.router.navigate(['/track']);
  }
}
